import React, { useRef, useState, useEffect } from "react";
import { Card, Form, Spinner, Button, Col, Row } from "react-bootstrap";
import "./OffShore Technical Interview.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import { useNavigate, useLocation } from "react-router-dom";
import { CheckCircleFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowLeft } from "react-icons/bs";
import {
  post_submissionOf_Terms_Yes_Or_No_Of_stage3_API,
  post_Technical_Interview_stage3_API,
  post_FinalSubmission_Of_stage3_API,
  getAllDataOfStage_3_ByPointer_ID,
  fetchLocationsForStage_3,
  post_sendStatusOfExemptionFormDownloadedAPI,
} from "../../../../api";
import Heading from "../../../Heading/Heading";
import NavigationButtons from "../../../Navigation Buttons/Navigation Buttons";
import samPDF from "./OffShore Sample Images/samPDF.jpeg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputGroup } from "react-bootstrap";
import { BsCalendar } from "react-icons/bs";
import UploadModal from "./OffShore Upload Modal/Upload Modal";
import LocationPopupModal from "./OffShore Location Popup Modal/Location Popup Modal";
import Loader from "../../../Loader/Loader";
import NotePopup from "./Note Popup/Note Popup";
import UploadDocuments from "./Extra Upload/Upload Documents";

const OffShoreTechnicalInterview = ({ onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pointerData = location.state?.pointerData;
  const [loading, setLoading] = useState(false);

  const [fetchLocationsByAPI, setFetchLocationsByAPI] = useState([]);

  useEffect(() => {
    const fetchLocationsFunction = async () => {
      try {
        const response = await fetchLocationsForStage_3(
          pointerData?.pointer_id
        );
        if (response.data?.response && response.data?.response?.data) {
          const locations = response?.data?.response?.data || [];
          const locationsByCountry = locations.reduce((acc, location) => {
            if (!acc[location.country]) {
              acc[location.country] = [];
            }
            acc[location.country].push(location);
            return acc;
          }, {});
          setFetchLocationsByAPI(locationsByCountry);
        } else {
          console.error(
            "Error fetching countries:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    if (pointerData?.pointer_id) {
      fetchLocationsFunction();
    }
  }, [pointerData?.pointer_id]);

  const [GuideLink, setGuideLink] = useState("");
  const [PDFLink, setPDFLink] = useState("");

  const [LocationModal, setLocationModal] = useState(false);

  const handleOpenLocationModal = () => {
    setLocationModal(true);
  };

  const handleCloseLocationModal = () => {
    setLocationModal(false);
  };

  const [TermsYesNo, setTermsYesNo] = useState("");

  const [CanShowExtraDocs, setCanShowExtraDocs] = useState(false);

  const [logoutClicked, setLogoutClicked] = useState(false);
  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);
  const [
    showConfirmationBackToDashboardModal,
    setShowConfirmationBackToDashboardModal,
  ] = useState(false);
  const [backToDashboardButtonClicked, setBackToDashboardButtonClicked] =
    useState(false);

  const [PDFdownloaded, setPDFdownloaded] = useState(false);

  const handlePDFDownload = async (e) => {
    getPreFilledDetailsFunction();
    e.preventDefault();
    setPDFdownloaded(true);
    window.open(PDFLink, "_blank");

    const formDataSubmissionEX = new FormData();
    formDataSubmissionEX.append("pointer_id", pointerData?.pointer_id);
    formDataSubmissionEX.append("stage", "stage_3");

    try {
      // setLoading(true);
      const response = await post_sendStatusOfExemptionFormDownloadedAPI(
        formDataSubmissionEX
      );
      // setLoading(false);

      if (response?.data?.response?.response === true) {
        getPreFilledDetailsFunction();
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Exemption Form Status"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in saving Exemption Form Status:", error);
      toast.error("Failed to save Exemption Form Status");
    }
  };

  const [comments, setComments] = useState("");

  const [receiptNumber, setReceiptNumber] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [preferredLocation, setPreferredLocation] = useState("");
  const [popupData, setPopupData] = useState([]);
  const [currentPopupIndex, setCurrentPopupIndex] = useState(0);
  const [NoteModal, setNoteModal] = useState(false);

  const handleCloseNoteModal = () => {
    setNoteModal(false);
    // Move to the next popup in the sequence
    setCurrentPopupIndex((prevIndex) => prevIndex + 1);
  };
  const [preferredLocationID, setPreferredLocationID] = useState("");
  const [address, setAddress] = useState("");

  const handleChangeLocation = (location) => {
    if (!location) return;

    if (TermsYesNo === "" || TermsYesNo === "no") {
      setPreferredLocationID(location.id);
      setPreferredLocation(location.city_name);
    } else if (TermsYesNo === "yes") {
      setPreferredLocationID("9");
      setPreferredLocation("Online (Via Zoom)");
    }

    setAddress(location.office_address);
    handleOpenLocationModal();
  };

  const handleClearLocation = () => {
    setPreferredLocation("");
    setAddress("");
  };

  const handleBack = () => {
    navigate("/user/view_application", { state: { pointerData } });
    toast.success("Navigated to Application Details.");
  };








  
  const [showConfModal, setShowConfModal] = useState(false);
  const handleConfModalClose = () => setShowConfModal(false);
  const handleConfModalShow = () => {
    setShowConfModal(true);
  };


  const handleFinalSubmissionOfStage3 = async (e) => {
    e.preventDefault();

    if (preferredLocation.trim() === "") {
      toast.error("Please select technical interview location.");
      document.getElementById("preferredLocationID").focus();
      return;
    }

    // if (comments.trim() === "") {
    //   toast.error("Please select practical interview location.");
    //   document.getElementById("commentID").focus();
    //   return;
    // }

    if (receiptNumber.trim() === "") {
      toast.error("Please select TRA Payment Receipt Number.");
      document.getElementById("RecieptNumberID").focus();
      return;
    }

    if (paymentDate === null) {
      toast.error("Please select Payment Date");
      document.getElementById("paymentDate").focus();
      return;
    }

    handleConfModalShow();
  }


  const handleFinalSubmissionOfStage3APIcalling = async () => {
    // e.preventDefault();

    const formattedPaymentDate =
      paymentDate &&
      `${paymentDate.getDate()}/${
        paymentDate.getMonth() + 1
      }/${paymentDate.getFullYear()}`;

    // const formData = new FormData();
    // formData.append("pointer_id", pointerData?.pointer_id);
    // formData.append("offline_location_id", preferredLocationID);
    // formData.append("preference_location", preferredLocation);
    // formData.append("preference_comment", comments);
    // formData.append("recipt_number", receiptNumber);
    // formData.append("payment_date", formattedPaymentDate);

    const formData = new FormData();
    // Helper function to handle null, undefined, or empty string
    const safeValue = (value) =>
      value == null ||
      value === "" ||
      value === "undefined" ||
      value === undefined
        ? ""
        : value;

    formData.append("pointer_id", safeValue(pointerData?.pointer_id));
    formData.append("offline_location_id", safeValue(preferredLocationID));
    formData.append("preference_location", safeValue(preferredLocation));
    formData.append("preference_comment", safeValue(comments));
    formData.append("recipt_number", safeValue(receiptNumber));
    formData.append("payment_date", safeValue(formattedPaymentDate));

    try {
      setLoading(true);
      const response = await post_Technical_Interview_stage3_API(
        pointerData?.pointer_id,
        formData
      );
      setLoading(false);

      if (response?.data?.response?.response === true) {
        getPreFilledDetailsFunction();

        if (TermsYesNo === "yes") {
          if (TRA_FileUploaded === false && Exemption_FileUploaded === false) {
            toast.warning(
              "Technical Interview saved successfully. Now please upload the TRA Payment Receipt."
            );
            return;
          }

          if (TRA_FileUploaded === true && Exemption_FileUploaded === false) {
            toast.warning(
              "Technical Interview & TRA Payment Receipt saved successfully. Now please upload the Exemption File."
            );
            return;
          }

          const formDataSubmissionFinal = new FormData();
          formDataSubmissionFinal.append("pointer_id", pointerData?.pointer_id);
          formDataSubmissionFinal.append("city_name", preferredLocation);

          try {
            setLoading(true);
            const response = await post_FinalSubmission_Of_stage3_API(
              pointerData?.pointer_id,
              formDataSubmissionFinal
            );
            setLoading(false);

            if (response?.data?.response?.response === true) {
              getPreFilledDetailsFunction();
              toast.success(
                "The Technical Interview has been scheduled successfully."
              );

              navigate("/user/view_application", { state: { pointerData } });
            } else {
              toast.error(
                response?.data?.response?.error_msg ||
                  "Failed to save Technical Interview"
              );
            }
          } catch (error) {
            setLoading(false);
            console.error("Error in saving Technical Interview:", error);
            toast.error("Failed to save Technical Interview");
          }
        }

        if (TermsYesNo === "no") {
          if (TRA_FileUploaded === false) {
            toast.warning(
              "Technical Interview saved successfully. Now please upload the TRA Payment Receipt."
            );
            return;
          }

          const formDataSubmissionFinal = new FormData();
          formDataSubmissionFinal.append("pointer_id", pointerData?.pointer_id);
          formDataSubmissionFinal.append("city_name", preferredLocation);

          try {
            setLoading(true);
            const response = await post_FinalSubmission_Of_stage3_API(
              pointerData?.pointer_id,
              formDataSubmissionFinal
            );

            console.log("whnhbkhshubhcywS<CJb", response);

            setLoading(false);

            if (response?.data?.response?.response === true) {
              getPreFilledDetailsFunction();
              toast.success(
                "The Technical Interview has been scheduled successfully."
              );

              navigate("/user/view_application", { state: { pointerData } });
            } else {
              toast.error(
                response?.data?.response?.error_msg ||
                  "Failed to save Technical Interview"
              );
            }
          } catch (error) {
            setLoading(false);
            console.error("Error in saving Technical Interview:", error);
            toast.error("Failed to save Technical Interview");
          }
        }
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Technical Interview."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in saving Technical Interview:", error);
      toast.error("Failed to save Technical Interview.");
    }
  };

  const handleChangeTerms = async (e) => {
    const value = e.target.value;
    setTermsYesNo(value);

    // if (value.trim() === "") {
    //   toast.error(
    //     "Please choose do you want to apply for an exemption for your client."
    //   );
    //   if (radioYesRef.current && radioNoRef.current) {
    //     if (!value) {
    //       radioYesRef.current.focus();
    //     } else {
    //       radioNoRef.current.focus();
    //     }
    //   }
    //   return;
    // }

    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append(
      "yes_no",
      value === "yes" ? "1" : value === "no" ? "0" : ""
    );

    try {
      setLoading(true);
      const response = await post_submissionOf_Terms_Yes_Or_No_Of_stage3_API(
        pointerData?.pointer_id,
        formData
      );
      setLoading(false);

      if (response?.data?.response?.response === true) {
        getPreFilledDetailsFunction();
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Technical Assessment"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in saving Technical Assessment:", error);
      toast.error("Failed to save Technical Assessment");
    }
  };

  const handleSavingAndNext = async (e) => {
    e.preventDefault();

    const formattedPaymentDate =
      paymentDate &&
      `${paymentDate.getDate()}/${
        paymentDate.getMonth() + 1
      }/${paymentDate.getFullYear()}`;

    if (preferredLocation.trim() === "") {
      toast.error("Please select technical interview location.");
      document.getElementById("preferredLocationID").focus();
      return;
    }

    // if (comments.trim() === "") {
    //   toast.error("Please select practical interview location.");
    //   document.getElementById("commentID").focus();
    //   return;
    // }

    if (receiptNumber.trim() === "") {
      toast.error("Please select TRA Payment Receipt Number.");
      document.getElementById("RecieptNumberID").focus();
      return;
    }

    if (paymentDate === null) {
      toast.error("Please select Payment Date");
      document.getElementById("paymentDate").focus();
      return;
    }

    // const formData = new FormData();
    // formData.append("pointer_id", pointerData?.pointer_id);
    // formData.append("offline_location_id", preferredLocationID);
    // formData.append("preference_location", preferredLocation);
    // formData.append("preference_comment", comments);
    // formData.append("recipt_number", receiptNumber);
    // formData.append("payment_date", formattedPaymentDate);

    const formData = new FormData();
    // Helper function to handle null, undefined, or empty string
    const safeValue = (value) =>
      value == null ||
      value === "" ||
      value === "undefined" ||
      value === undefined
        ? ""
        : value;

    formData.append("pointer_id", safeValue(pointerData?.pointer_id));
    formData.append("offline_location_id", safeValue(preferredLocationID));
    formData.append("preference_location", safeValue(preferredLocation));
    formData.append("preference_comment", safeValue(comments));
    formData.append("recipt_number", safeValue(receiptNumber));
    formData.append("payment_date", safeValue(formattedPaymentDate));

    try {
      setLoading(true);
      const response = await post_Technical_Interview_stage3_API(
        pointerData?.pointer_id,
        formData
      );
      setLoading(false);

      if (response?.data?.response?.response === true) {
        getPreFilledDetailsFunction();

        if (TermsYesNo === "yes") {
          if (TRA_FileUploaded === false && Exemption_FileUploaded === false) {
            toast.warning(
              "Technical Interview saved successfully. Now please upload the TRA Payment Receipt."
            );
            return;
          }

          if (TRA_FileUploaded === true && Exemption_FileUploaded === false) {
            toast.warning(
              "Technical Interview & TRA Payment Receipt saved successfully. Now please upload the Exemption File."
            );
            return;
          }

          toast.warning(
            "Technical Interview saved successfully, Now Please Submit Stage 3."
          );
          navigate("/user/view_application", { state: { pointerData } });
        }

        if (TermsYesNo === "no") {
          if (TRA_FileUploaded === false) {
            toast.warning(
              "Technical Interview saved successfully. Now please upload the TRA Payment Receipt."
            );
            return;
          }

          toast.warning(
            "Technical Interview saved successfully, Now Please Submit Stage 3."
          );
          navigate("/user/view_application", { state: { pointerData } });
        }
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Technical Interview."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in saving Technical Interview:", error);
      toast.error("Failed to save Technical Interview.");
    }
  };

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const handleConfirmationBackToDashboardNo = () => {
    setShowConfirmationBackToDashboardModal(false);
  };

  const handleConfirmationBackToDashboardYes = () => {
    // navigate("/user/dashboard");
    // setShowConfirmationBackToDashboardModal(false);
    // toast.success("Navigated to Dashboard.");

    navigate("/user/view_application", {
      state: { pointerData },
    });
    setShowConfirmationBackToDashboardModal(false);
    toast.success("Navigated to View Application.");
  };

  const handleBackToView = () => {
    setBackToDashboardButtonClicked(true);
    setShowConfirmationBackToDashboardModal(true);
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const radioYesRef = useRef(null);
  const radioNoRef = useRef(null);

  const [SubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [ApplicationNo, setApplicationNo] = useState("");

  const [TRA_FileUploaded, setTRA_FileUploaded] = useState(false);

  const [ShowTRA_UploadModal, setShowTRA_UploadModal] = useState(false);

  const handleOpenTRA_UploadModal = () => {
    setShowTRA_UploadModal(true);
  };

  const handleCloseTRA_UploadModal = () => {
    setShowTRA_UploadModal(false);
  };

  const [Exemption_FileUploaded, setExemption_FileUploaded] = useState(false);

  const [ShowExemption_UploadModal, setShowExemption_UploadModal] =
    useState(false);

  const handleOpenExemption_UploadModal = () => {
    setShowExemption_UploadModal(true);
  };

  const handleCloseExemption_UploadModal = () => {
    setShowExemption_UploadModal(false);
  };

  const getPreFilledDetailsFunction = async () => {
    try {
      setLoading(true);
      const response = await getAllDataOfStage_3_ByPointer_ID(
        pointerData?.pointer_id
      );
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.data
      ) {
        const stage_Details = response?.data?.response.data;

        if (stage_Details?.payment_date) {
          const originalIncomingStringForDate = stage_Details?.payment_date;

          // Split the string into parts
          const parts = originalIncomingStringForDate.split(" ")[0].split("-");

          // Parse the parts into integers
          const year = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
          const day = parseInt(parts[2], 10);

          // Create a Date object
          const modifiedDate = new Date(year, month, day);

          // Set state values
          setPaymentDate(modifiedDate);
        } else {
          setPaymentDate("");
        }

        // setPreferredLocation(stage_Details?.preference_location);
        setAddress(stage_Details?.office_address);
        setComments(stage_Details?.preference_comment);
        setReceiptNumber(stage_Details?.receipt_number);
        setApplicationNo(stage_Details?.application_number);
        setGuideLink(stage_Details?.guide_link);
        setPDFLink(stage_Details?.ex_pdf_link);

        if (
          stage_Details?.exemption_yes_no === "0" ||
          stage_Details?.exemption_yes_no === "no"
        ) {
          setTermsYesNo("no");
          setCanShowExtraDocs(true);
        } else if (
          stage_Details?.exemption_yes_no === "1" ||
          stage_Details?.exemption_yes_no === "yes"
        ) {
          setTermsYesNo("yes");
          setCanShowExtraDocs(true);
        }

        if (
          stage_Details?.download_ex_form === "0" ||
          stage_Details?.download_ex_form === ""
        ) {
          setPDFdownloaded(false);
          setCanShowExtraDocs(false);
        } else if (stage_Details?.download_ex_form === "1") {
          setPDFdownloaded(true);
          setCanShowExtraDocs(true);
        }

        if (
          stage_Details?.exemption_yes_no === "0" ||
          stage_Details?.exemption_yes_no === "" ||
          stage_Details?.exemption_yes_no === "no"
        ) {
          setPreferredLocation(stage_Details?.preference_location);
          setPreferredLocationID(stage_Details?.offline_location_id);
        } else if (
          stage_Details?.exemption_yes_no === "1" ||
          stage_Details?.exemption_yes_no === "yes"
        ) {
          setPreferredLocation("Online (Via Zoom)");
          setPreferredLocationID("9");
        }

        if (stage_Details?.is_tra_doc_uploaded === 0) {
          setTRA_FileUploaded(false);
        } else if (stage_Details?.is_tra_doc_uploaded === 1) {
          setTRA_FileUploaded(true);
        }

        if (stage_Details?.popup_data?.length > 0) {
          setPopupData(stage_Details.popup_data);
          setNoteModal(true); // Show the first popup
        }

        if (stage_Details?.is_ex_doc_uploaded === 0) {
          setExemption_FileUploaded(false);
        } else if (stage_Details?.is_ex_doc_uploaded === 1) {
          setExemption_FileUploaded(true);
        }

        // Update the submit button disabled state
        if (
          stage_Details?.payment_date !== "" &&
          stage_Details?.payment_date !== null &&
          stage_Details?.preference_location !== "" &&
          stage_Details?.office_address !== "" &&
          stage_Details?.receipt_number !== "" &&
          ((stage_Details?.exemption_yes_no === "1" &&
            stage_Details?.is_ex_doc_uploaded === 1 &&
            stage_Details?.is_tra_doc_uploaded === 1) ||
            (stage_Details?.exemption_yes_no === "0" &&
              stage_Details?.is_tra_doc_uploaded === 1))
        ) {
          setSubmitButtonDisabled(false);
        } else {
          setSubmitButtonDisabled(true);
        }
      } else {
        console.error(
          "Error fetching stage 4 details data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching stage 4 details data:", error);
    }
  };

  useEffect(() => {
    if (pointerData?.pointer_id) {
      getPreFilledDetailsFunction(pointerData.pointer_id);
    }
  }, [pointerData?.pointer_id]);

  useEffect(() => {
    // Show the next popup if it exists
    if (currentPopupIndex < popupData.length) {
      setNoteModal(true);
    }
  }, [currentPopupIndex, popupData]);

  const handleAutoSave = async () => {
    const formattedPaymentDate =
      paymentDate &&
      `${paymentDate.getDate()}/${
        paymentDate.getMonth() + 1
      }/${paymentDate.getFullYear()}`;

    const formData = new FormData();
    // Helper function to handle null, undefined, or empty string
    const safeValue = (value) =>
      value == null ||
      value === "" ||
      value === "undefined" ||
      value === undefined
        ? ""
        : value;

    formData.append("pointer_id", safeValue(pointerData?.pointer_id));
    formData.append("offline_location_id", safeValue(preferredLocationID));
    formData.append("preference_location", safeValue(preferredLocation));
    formData.append("preference_comment", safeValue(comments));
    formData.append("recipt_number", safeValue(receiptNumber));
    formData.append("payment_date", safeValue(formattedPaymentDate));

    try {
      // setLoading(true);
      const response = await post_Technical_Interview_stage3_API(
        pointerData?.pointer_id,
        formData
      );
      // setLoading(false);

      if (response?.data?.response?.response === true) {
        // getPreFilledDetailsFunction();
      } else {
        // toast.error(
        //   response?.data?.response?.error_msg ||
        //     "Failed to save Technical Interview."
        // );
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error in saving Technical Interview:", error);
      // toast.error("Failed to save Technical Interview.");
    }
  };
  useEffect(() => {
    handleAutoSave();
  }, [
    preferredLocationID,
    preferredLocation,
    comments,
    receiptNumber,
    paymentDate,
  ]);

  return (
    <>
      <Row>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBackToView}
            style={{
              color: "#055837",
              marginLeft: "25px",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <BsArrowLeft className="me-0" />
            Back to View Application
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Stage 3 - Technical Interview " />
      </Row>

      <div className="practical-upload-container-contact-details">
        <Card className="shadow practical-upload-card-contact-details">
          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-1 mt-1">
                  Application No. :{" "}
                  <b style={{ color: "#055837" }}>
                    {/* {pointerData?.portal_refrance_no} */}
                    {ApplicationNo}
                  </b>
                </div>
              </div>
            </div>

            <Card.Body>
              <Form onSubmit={handleSavingAndNext}>
                {/* {loading && (
                  <div className="d-flex justify-content-center mt-3">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )} */}

                {loading && <Loader />}

                <div
                  className="rounded p-2 mt-1 mb-2 bg-warning rounded"
                  style={{ padding: "30px" }}
                >
                  <h5 className="mb-2">Note : </h5>
                  <p>
                    Trades Recognition Australia (TRA) recently announced that
                    from 1st March 2023 online Technical Interviews could be
                    conducted in locations not approved by TRA (such as the
                    applicant’s home), should the applicant have extenuating
                    circumstances preventing them from traveling to an{" "}
                    <a target="/blank" href={GuideLink}>
                      <b>
                        <u>
                          Approved Assessment Centre (Click here to view the
                          updated list).
                        </u>
                      </b>
                    </a>
                  </p>
                </div>
                <div
                  className=" rounded p-2 mb-2 "
                  style={{ backgroundColor: "#ebebeb" }}
                >
                  <h4 className="">Circumstances Considered</h4>
                  <ul>
                    <li className="">
                      {" "}
                      The applicant will have to take a flight to reach the
                      closest assessment centre.
                    </li>
                    <li className="">
                      The applicant is located more than three hours’ drive away
                      from the closest assessment centre;
                    </li>
                    <li className="">
                      The applicant is unable to take any time off due to work
                      obligations; for example, the employer does not grant a
                      leave of absence; (Supporting evidence required)
                    </li>
                    <li className="">
                      Attending an assessment centre would have a negative
                      financial impact on the applicant (excessive travel
                      expenses, potential income loss, etc.); (Supporting
                      evidence required)
                    </li>
                    <li className="">
                      The applicant's health prevents them from travelling, and
                      doing so might worsen their condition; (Supporting
                      evidence required)
                    </li>
                    <li className="">
                      Plumber (General) and Electrician (General) applicants may
                      complete their Technical interviews from home prior to
                      their Technical assessment, provided they have a
                      dependable internet connection.
                    </li>
                  </ul>
                </div>

                <div
                  className="row p-2 mb-2"
                  style={{ padding: "30px" }}
                  disabled=""
                >
                  <h4 className="text-center p-2" style={{ fontSize: "20px" }}>
                    Based on the above information, do you want to apply for an
                    exemption for your client?
                  </h4>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Group controlId="termsRadio" className="d-flex">
                    <Form.Check
                      type="radio"
                      label="Yes"
                      value="yes"
                      id="termsRadioYes"
                      name="termsRadio"
                      className="me-5 radio-with-border"
                      checked={TermsYesNo === "yes"}
                      ref={radioYesRef} // Attach the ref here
                      // onChange={handleChangeTerms}
                      onClick={handleChangeTerms}
                      style={{ cursor: "pointer" }}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      value="no"
                      id="termsRadioNo"
                      name="termsRadio"
                      className="me-5 radio-with-border"
                      checked={TermsYesNo === "no"}
                      ref={radioNoRef}
                      // onChange={handleChangeTerms}
                      onClick={handleChangeTerms}
                      style={{ cursor: "pointer" }}
                    />
                  </Form.Group>
                </div>

                <hr className="mt-2 mb-2 thick-hr" />

                {TermsYesNo === "yes" && (
                  <div className="row">
                    {PDFdownloaded === false && (
                      <>
                        <div className="col-12">
                          <h4
                            className="text-center p-2 text-center mt-3 mb-3 bg-warning rounded"
                            style={{ fontSize: "20px" }}
                          >
                            Download the exemption request form below which
                            needs to be completed by the applicant before
                            proceeding.
                          </h4>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <button
                            onClick={handlePDFDownload}
                            className="btn btn_green_yellow col-12 col-md-6 col-lg-3"
                            style={{
                              fontSize: "17px",
                              padding: "10px 18px",
                              margin: "10px 0",
                            }}
                          >
                            Download Exemption Form
                          </button>
                          <input
                            type="hidden"
                            value="0"
                            id="download_ex_form"
                          />
                        </div>
                      </>
                    )}

                    {PDFdownloaded === true && (
                      <div className="form-group">
                        <div className="row mb-3 mt-1 bg-offwhite">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="preferredLocationLabel">
                              <Form.Label
                                htmlFor="preferredLocationID"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ Preferred Interview Location{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7">
                            <Form.Group controlId="preferredLocationID">
                              <Form.Select
                                value={preferredLocation}
                                onChange={(e) =>
                                  setPreferredLocation(e.target.value)
                                }
                              >
                                <option
                                  className="dropdown-options"
                                  value="Online (Via Zoom)"
                                >
                                  Online (Via Zoom)
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="commentLabel">
                              <Form.Label
                                htmlFor="commentID"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ Comments
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7">
                            <Form.Group controlId="commentID">
                              <Form.Control
                                as="textarea"
                                rows={6}
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                                className="w-100 mx-auto"
                                placeholder="Examples: 
                     * The applicant would need 2 weeks notice for the interview. 
                     * The applicant is currently away, kindly schedule the interview after DD/MM/YYYY."
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row mb-3 bg-offwhite">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="RecieptNumberLabel">
                              <Form.Label
                                htmlFor="RecieptNumberID"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ TRA Payment Receipt Number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7">
                            <Form.Group controlId="RecieptNumberID">
                              <Form.Control
                                type="number"
                                value={receiptNumber}
                                onChange={(e) =>
                                  setReceiptNumber(e.target.value)
                                }
                                required
                                placeholder="Enter TRA Payment Receipt Number"
                                className="w-100 mx-auto"
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row mb-0">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="paymentDateLabel">
                              <Form.Label
                                htmlFor="paymentDate"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ Payment Date (dd/mm/yyyy){" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7">
                            <Form.Group controlId="paymentDate">
                              <InputGroup>
                                <DatePicker
                                  selected={paymentDate}
                                  onChange={(date) => setPaymentDate(date)}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Select Payment Date"
                                  className="form-control custom-cursor"
                                  showYearDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={100}
                                  maxDate={new Date()}
                                />
                                <InputGroup.Text style={{ fontSize: "1rem" }}>
                                  <BsCalendar size="0.7em" />
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </div>
                        </div>

                        {TRA_FileUploaded ? (
                          <div className="docUploaded">
                            <div
                              className="shadow mt-3 p-4"
                              style={{
                                border: "1px solid #055837",
                                color: "#055837",
                                borderRadius: "5px",
                              }}
                            >
                              <div className="accordion-header-wrapper">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  onClick={handleOpenTRA_UploadModal}
                                >
                                  <p
                                    className="accordion-header"
                                    id="h1eadingOne"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span style={{ marginRight: "10px" }}>
                                        <CheckCircleFill />
                                      </span>
                                      <span>
                                        TRA Payment Receipt
                                        <span style={{ color: "red" }}>*</span>,
                                        Uploaded Successfully!
                                      </span>
                                    </div>
                                  </p>
                                </button>
                                <span style={{ marginRight: "20px" }}>✔️</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="bg-white shadow mt-3 p-4 docNotUploaded"
                            style={{
                              border: "1px solid #ffcc01",
                              borderRadius: "5px",
                            }}
                          >
                            <div className="accordion-header-wrapper">
                              <button
                                className="accordion-button"
                                type="button"
                                onClick={handleOpenTRA_UploadModal}
                              >
                                <p
                                  className="accordion-header"
                                  id="h1eadingOne"
                                >
                                  <div className="d-flex align-items-center">
                                    <span className="bullet-point"></span>
                                    <span>
                                      Upload TRA Payment Receipt{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </span>
                                  </div>
                                </p>
                              </button>
                              <span style={{ marginRight: "20px" }}>⟫</span>
                            </div>
                          </div>
                        )}

                        {Exemption_FileUploaded ? (
                          <div className="docUploaded">
                            <div
                              className="shadow mt-3 p-4"
                              style={{
                                border: "1px solid #055837",
                                color: "#055837",
                                borderRadius: "5px",
                              }}
                            >
                              <div className="accordion-header-wrapper">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  onClick={handleOpenExemption_UploadModal}
                                >
                                  <p
                                    className="accordion-header"
                                    id="h1eadingOne"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span style={{ marginRight: "10px" }}>
                                        <CheckCircleFill />
                                      </span>
                                      <span>
                                        Exemption Form{" "}
                                        <span style={{ color: "red" }}>*</span>,
                                        Uploaded Successfully!
                                      </span>
                                    </div>
                                  </p>
                                </button>
                                <span style={{ marginRight: "20px" }}>✔️</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="bg-white shadow mt-3 p-4 docNotUploaded"
                            style={{
                              border: "1px solid #ffcc01",
                              borderRadius: "5px",
                            }}
                          >
                            <div className="accordion-header-wrapper">
                              <button
                                className="accordion-button"
                                type="button"
                                onClick={handleOpenExemption_UploadModal}
                              >
                                <p
                                  className="accordion-header"
                                  id="h1eadingOne"
                                >
                                  <div className="d-flex align-items-center">
                                    <span className="bullet-point"></span>
                                    <span>
                                      Upload Exemption Form{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </span>
                                  </div>
                                </p>
                              </button>
                              <span style={{ marginRight: "20px" }}>⟫</span>
                            </div>
                          </div>
                        )}

                        {(TermsYesNo === "no" || TermsYesNo === "yes") &&
                          (CanShowExtraDocs || TermsYesNo === "no") && (
                            <div style={{ marginTop: "15px" }}>
                              <UploadDocuments />
                            </div>
                          )}

                        <hr className="mb-2 mt-2 thick-hr" />

                        {/* Save&Exit and Next Buttons */}
                        <div
                          className="button-group text-center mb-1"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          {/* Back and Save & Exit and Next Buttons */}
                          <div
                            className="button-group text-center mb-1"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            {/* Back Button */}
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{
                                backgroundColor: "#ffcc01",
                                color: "#055837",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                border: "none",
                                borderRadius: "5px",
                                marginTop: "30px",
                                marginBottom: "20px",
                                transition: "background-color 0.3s, color 0.3s",
                              }}
                              onClick={handleBack}
                              onMouseEnter={(e) => {
                                e.target.style.backgroundColor = "#055837";
                                e.target.style.color = "#ffcc01";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#ffcc01";
                                e.target.style.color = "#055837";
                              }}
                            >
                              Back
                            </button>

                            {/* Save & Exit Button */}
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleSavingAndNext}
                              style={{
                                backgroundColor: "#055837",
                                color: "#ffcc01",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                border: "none",
                                borderRadius: "5px",
                                marginTop: "30px",
                                marginBottom: "20px",
                                transition: "background-color 0.3s, color 0.3s",
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.backgroundColor = "#ffcc01";
                                e.target.style.color = "#055837";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#055837";
                                e.target.style.color = "#ffcc01";
                              }}
                            >
                              Save & Exit
                            </button>

                            {/* Next Button */}
                            <button
                              type="button"
                              className="btn btn-primary"
                              // disabled={SubmitButtonDisabled}
                              style={{
                                backgroundColor: "#ffcc01",
                                color: "#055837",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                border: "none",
                                borderRadius: "5px",
                                marginTop: "30px",
                                marginBottom: "20px",
                                transition: "background-color 0.3s, color 0.3s",
                              }}
                              onClick={handleFinalSubmissionOfStage3}
                              onMouseEnter={(e) => {
                                e.target.style.backgroundColor = "#055837";
                                e.target.style.color = "#ffcc01";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.backgroundColor = "#ffcc01";
                                e.target.style.color = "#055837";
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {TermsYesNo === "no" && (
                  <div className="form-group">
                    <div className="row mb-3 mt-2 bg-offwhite">
                      <div className="col-md-5 d-flex align-items-center justify-content-start">
                        <Form.Group controlId="preferredLocationLabel">
                          <Form.Label
                            htmlFor="preferredLocationID"
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            ▸ Preferred Interview Location{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                        </Form.Group>
                      </div>

                      <div className="col-md-7">
                        <Form.Group controlId="preferredLocationID">
                          <Form.Select
                            value={preferredLocation || ""}
                            style={{cursor:"pointer"}}
                            onChange={(e) => {
                              const country =
                                e.target.options[e.target.selectedIndex].dataset
                                  .country;
                              if (fetchLocationsByAPI[country]) {
                                const selectedLocation = fetchLocationsByAPI[
                                  country
                                ].find(
                                  (loc) => loc.city_name === e.target.value
                                );
                                if (selectedLocation) {
                                  handleChangeLocation(selectedLocation);
                                }
                              }
                            }}
                          >
                            <option
                              className="dropdown-options"
                              value=""
                              disabled
                            >
                              Select Location
                            </option>
                            {Object.keys(fetchLocationsByAPI).map((country) => (
                              <optgroup key={country} label={country}>
                                {fetchLocationsByAPI[country].map(
                                  (location) => (
                                    <option
                                      key={location.id}
                                      value={location.city_name}
                                      data-country={country}
                                    >
                                      {location.city_name} - {location.location}
                                    </option>
                                  )
                                )}
                              </optgroup>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>

                    {preferredLocation !== "" && (
                      <div className="row bg-warning rounded p-2 text-size-control mb-3 ">
                        <div className="col-12"></div>
                        <div className="text-center d-flex justify-content-center align-items-center">
                          <strong
                            style={{ marginRight: "10px", minWidth: "70px" }}
                          >
                            Address:
                          </strong>
                          <div>{address}</div>
                        </div>
                      </div>
                    )}

                    <div className="row mb-3">
                      <div className="col-md-5 d-flex align-items-center justify-content-start">
                        <Form.Group controlId="commentLabel">
                          <Form.Label
                            htmlFor="commentID"
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            ▸ Comments
                          </Form.Label>
                        </Form.Group>
                      </div>
                      <div className="col-md-7">
                        <Form.Group controlId="commentID">
                          <Form.Control
                            as="textarea"
                            rows={6}
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                            className="w-100 mx-auto"
                            placeholder="Examples: 
                          * The applicant would need 2 weeks notice for the interview. 
                          * The applicant is currently away, kindly schedule the interview after DD/MM/YYYY."
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row mb-3 bg-offwhite">
                      <div className="col-md-5 d-flex align-items-center justify-content-start">
                        <Form.Group controlId="RecieptNumberLabel">
                          <Form.Label
                            htmlFor="RecieptNumberID"
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            ▸ TRA Payment Receipt Number{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                        </Form.Group>
                      </div>
                      <div className="col-md-7">
                        <Form.Group controlId="RecieptNumberID">
                          <Form.Control
                            type="number"
                            value={receiptNumber}
                            onChange={(e) => setReceiptNumber(e.target.value)}
                            required
                            placeholder="Enter TRA Payment Receipt Number"
                            className="w-100 mx-auto"
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row mb-0">
                      <div className="col-md-5 d-flex align-items-center justify-content-start">
                        <Form.Group controlId="paymentDateLabel">
                          <Form.Label
                            htmlFor="paymentDate"
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            ▸ Payment Date (dd/mm/yyyy){" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                        </Form.Group>
                      </div>
                      <div className="col-md-7">
                        <Form.Group controlId="paymentDate">
                          <InputGroup>
                            <DatePicker
                              selected={paymentDate}
                              onChange={(date) => setPaymentDate(date)}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="Select Payment Date"
                              className="form-control custom-cursor"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={100}
                              maxDate={new Date()}
                            />
                            <InputGroup.Text style={{ fontSize: "1rem" }}>
                              <BsCalendar size="0.7em" />
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </div>

                    {TRA_FileUploaded ? (
                      <div className="docUploaded">
                        <div
                          className="shadow mt-0 p-4"
                          style={{
                            border: "1px solid #055837",
                            color: "#055837",
                            borderRadius: "5px",
                          }}
                        >
                          <div className="accordion-header-wrapper">
                            <button
                              className="accordion-button"
                              type="button"
                              onClick={handleOpenTRA_UploadModal}
                            >
                              <p className="accordion-header" id="h1eadingOne">
                                <div className="d-flex align-items-center">
                                  <span style={{ marginRight: "10px" }}>
                                    <CheckCircleFill />
                                  </span>
                                  <span>
                                    TRA Payment Receipt{" "}
                                    <span style={{ color: "red" }}>*</span>,
                                    Uploaded Successfully!
                                  </span>
                                </div>
                              </p>
                            </button>
                            <span style={{ marginRight: "20px" }}>✔️</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="bg-white shadow mt-3 p-4 docNotUploaded"
                        style={{
                          border: "1px solid #ffcc01",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="accordion-header-wrapper">
                          <button
                            className="accordion-button"
                            type="button"
                            onClick={handleOpenTRA_UploadModal}
                          >
                            <p className="accordion-header" id="h1eadingOne">
                              <div className="d-flex align-items-center">
                                <span className="bullet-point"></span>
                                <span>
                                  Upload TRA Payment Receipt{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </span>
                              </div>
                            </p>
                          </button>
                          <span style={{ marginRight: "20px" }}>⟫</span>
                        </div>
                      </div>
                    )}

                    {(TermsYesNo === "no" || TermsYesNo === "yes") &&
                      (CanShowExtraDocs || TermsYesNo === "no") && (
                        <div style={{ marginTop: "15px" }}>
                          <UploadDocuments />
                        </div>
                      )}

                    <hr className="mb-2 mt-2 thick-hr" />

                    {/* Save&Exit and Next Buttons */}
                    <div
                      className="button-group text-center mb-1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      {/* Back and Save & Exit and Next Buttons */}
                      <div
                        className="button-group text-center mb-1"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        {/* Back Button */}
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{
                            backgroundColor: "#ffcc01",
                            color: "#055837",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                            border: "none",
                            borderRadius: "5px",
                            marginTop: "30px",
                            marginBottom: "20px",
                            transition: "background-color 0.3s, color 0.3s",
                          }}
                          onClick={handleBack}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#055837";
                            e.target.style.color = "#ffcc01";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "#ffcc01";
                            e.target.style.color = "#055837";
                          }}
                        >
                          Back
                        </button>

                        {/* Save & Exit Button */}
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSavingAndNext}
                          style={{
                            backgroundColor: "#055837",
                            color: "#ffcc01",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                            border: "none",
                            borderRadius: "5px",
                            marginTop: "30px",
                            marginBottom: "20px",
                            transition: "background-color 0.3s, color 0.3s",
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#ffcc01";
                            e.target.style.color = "#055837";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "#055837";
                            e.target.style.color = "#ffcc01";
                          }}
                        >
                          Save & Exit
                        </button>

                        {/* Next Button */}
                        <button
                          type="button"
                          className="btn btn-primary"
                          // disabled={SubmitButtonDisabled}
                          style={{
                            backgroundColor: "#ffcc01",
                            color: "#055837",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                            border: "none",
                            borderRadius: "5px",
                            marginTop: "30px",
                            marginBottom: "20px",
                            transition: "background-color 0.3s, color 0.3s",
                          }}
                          onClick={handleFinalSubmissionOfStage3}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#055837";
                            e.target.style.color = "#ffcc01";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "#ffcc01";
                            e.target.style.color = "#055837";
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            </Card.Body>
          </Card.Body>

          {backToDashboardButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackToDashboardModal}
              onHide={handleConfirmationBackToDashboardNo}
              onConfirm={handleConfirmationBackToDashboardYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the View Application page?"
            />
          )}

          {logoutClicked && (
            <ConfirmationModal
              show={showConfirmationModalForLogout}
              onHide={handleConfirmationLogoutNo}
              onConfirm={handleConfirmationLogoutYes}
              title="Confirm Exit"
              message="Are you sure you want to log out?"
            />
          )}

          {ShowTRA_UploadModal && (
            <UploadModal
              show={ShowTRA_UploadModal}
              onHide={handleCloseTRA_UploadModal}
              pointerData={pointerData}
              getUploadedDocsList={getPreFilledDetailsFunction}
              ModalHeading={"TRA Payment Receipt"}
              ModalID={"19"}
            />
          )}

          {ShowExemption_UploadModal && (
            <UploadModal
              show={ShowExemption_UploadModal}
              onHide={handleCloseExemption_UploadModal}
              pointerData={pointerData}
              getUploadedDocsList={getPreFilledDetailsFunction}
              ModalHeading={"Exemption Form"}
              ModalID={"43"}
            />
          )}

          {LocationModal && (
            <LocationPopupModal
              show={handleOpenLocationModal}
              onHide={handleCloseLocationModal}
              title="Confirm Location"
              preferredLocation={preferredLocation}
              preferredLocationID={preferredLocationID}
              pointerData={pointerData}
              handleClearLocation={handleClearLocation}
              getPreFilledDetailsFunction={getPreFilledDetailsFunction}
            />
          )}






{showConfModal && (
            <ConfirmationModal
              show={showConfModal}
              onHide={handleConfModalClose}
              onConfirm={handleFinalSubmissionOfStage3APIcalling}
              title="Confirm Submission"
              message="Do you confirm that you have discussed the criteria and consequences with the applicant and wish to submit Stage 3 Application ?"
            />
          )}




          <div>
            {popupData.length > 0 && currentPopupIndex < popupData.length && (
              <NotePopup
                show={NoteModal}
                onHide={handleCloseNoteModal}
                title="Important!"
                matter={popupData[currentPopupIndex].data}
              />
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default OffShoreTechnicalInterview;
