import React from 'react';
import AQATO_header_image from "./AQATO_header_image.jpg";
import "./Header.css"; 

const Header = () => {
  return (
    <div className="header-container">
      <div className="logo-container">
        <img src={AQATO_header_image} alt="AQATO_image" className="header-image" />
      </div>
    </div>
  );
};

export default Header;
