import React, { useState, useEffect } from "react";
import { Card, Form, Spinner, Button, Col, Row } from "react-bootstrap";
import "./Practical Assessment.css";
import ConfirmationModal from "../../Confirmation Modal/ConfirmationModal";
import { useNavigate, useLocation } from "react-router-dom";
import { XCircleFill, CheckCircleFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowLeft } from "react-icons/bs";
import {
  post_Practical_Interview_stage4_API,
  post_FinalSubmission_Of_stage4_API,
  fetchLocationsForStage_4,
  getAllDataOfStage_4_ByPointer_ID,
} from "../../../api";
import Heading from "../../Heading/Heading";
import NavigationButtons from "../../Navigation Buttons/Navigation Buttons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputGroup } from "react-bootstrap";
import { BsCalendar } from "react-icons/bs";
import UploadModal from "./Upload Modal/Upload Modal";
import LocationPopupModal from "./Location Popup Modal/Location Popup Modal";
import Loader from "../../Loader/Loader";
import UploadDocuments from "./Extra Upload/Upload Documents";
import NotePopup from "./Note Popup/Note Popup";

const PracticalAssessment = ({ onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pointerData = location.state?.pointerData;
  const [loading, setLoading] = useState(false);

  const [NoteModal, setNoteModal] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [currentPopupIndex, setCurrentPopupIndex] = useState(0);

  const handleCloseNoteModal = () => {
    setNoteModal(false);
    // Move to the next popup in the sequence
    setCurrentPopupIndex((prevIndex) => prevIndex + 1);
  };

  const [fetchLocationsByAPI, setFetchLocationsByAPI] = useState([]);
  useEffect(() => {
    const fetchLocationsFunction = async () => {
      try {
        const response = await fetchLocationsForStage_4(
          pointerData?.pointer_id
        );
        if (response.data?.response && response.data?.response?.data) {
          const locations = response?.data?.response?.data?.location || {};
          setFetchLocationsByAPI(locations);
        } else {
          console.error(
            "Error fetching countries:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    if (pointerData?.pointer_id) {
      fetchLocationsFunction();
    }
  }, [pointerData?.pointer_id]);
  const [SubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [LocationModal, setLocationModal] = useState(false);

  const handleOpenLocationModal = () => {
    setLocationModal(true);
  };

  const handleCloseLocationModal = () => {
    setLocationModal(false);
  };

  const [FileUploaded, setFileUploaded] = useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleOpenUploadModal = () => {
    setShowUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setShowUploadModal(false);
  };

  const [logoutClicked, setLogoutClicked] = useState(false);

  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);
  const [
    showConfirmationBackToDashboardModal,
    setShowConfirmationBackToDashboardModal,
  ] = useState(false);
  const [backToDashboardButtonClicked, setBackToDashboardButtonClicked] =
    useState(false);
  const [ApplicationNo, setApplicationNo] = useState("");

  const [comments, setComments] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [preferredLocation, setPreferredLocation] = useState("");

  const [preferredLocationID, setPreferredLocationID] = useState("");

  const [address, setAddress] = useState("");

  const handleChangeLocation = (location) => {
    setPreferredLocation(location.city_name);
    setPreferredLocationID(location.id);
    setAddress(location.office_address);
    handleOpenLocationModal();
  };

  const handleClearLocation = () => {
    setPreferredLocation("");
    setAddress("");
  };

  const handleBack = () => {
    navigate("/user/view_application", { state: { pointerData } });
    toast.success("Navigated to Application Details.");
  };

  const [showConfirmationSubmitModal, setShowConfirmationSubmitModal] =
    useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const handleConfirmationSubmitNo = () => {
    setShowConfirmationSubmitModal(false);
  };

  const handleNext = async (e) => {
    e.preventDefault();

    setSubmitButtonClicked(true);
    setShowConfirmationSubmitModal(true);
  };

  const handleConfirmationSubmitYes = async (e) => {
    e.preventDefault();

    const formattedPaymentDate =
      paymentDate &&
      `${paymentDate.getDate()}/${
        paymentDate.getMonth() + 1
      }/${paymentDate.getFullYear()}`;

    if (preferredLocation === "") {
      toast.error("Please select practical interview location.");
      document.getElementById("preferredLocationID").focus();
      return;
    }

    // if (comments.trim() === "") {
    //   toast.error("Please select practical interview location.");
    //   document.getElementById("commentID").focus();
    //   return;
    // }

    if (receiptNumber === "") {
      toast.error("Please select TRA Payment Receipt Number.");
      document.getElementById("RecieptNumberID").focus();
      return;
    }

    if (paymentDate === null) {
      toast.error("Please select Payment Date");
      document.getElementById("paymentDate").focus();
      return;
    }

    // if (FileUploaded === false) {
    //   toast.error("Please upload the TRA Payment Receipt.");
    //   return;
    // }

    // const formData = new FormData();
    // formData.append("pointer_id", pointerData?.pointer_id);
    // formData.append("offline_location_id", preferredLocationID);
    // formData.append("preference_location", preferredLocation);
    // formData.append("preference_comment", comments);
    // formData.append("recipt_number", receiptNumber);
    // formData.append("payment_date", formattedPaymentDate);

    const safeValue = (value) =>
      value == null ||
      value === "" ||
      value === "undefined" ||
      value === undefined
        ? ""
        : value;

    const formData = new FormData();
    formData.append("pointer_id", safeValue(pointerData?.pointer_id));
    formData.append("offline_location_id", safeValue(preferredLocationID));
    formData.append("preference_location", safeValue(preferredLocation));
    formData.append("preference_comment", safeValue(comments));
    formData.append("recipt_number", safeValue(receiptNumber));
    formData.append("payment_date", safeValue(formattedPaymentDate));

    try {
      setLoading(true);
      const response = await post_Practical_Interview_stage4_API(
        pointerData?.pointer_id,
        formData
      );
      setLoading(false);

      if (response?.data?.response?.response === true) {
        getPreFilledDetailsFunction();

        if (FileUploaded === false) {
          toast.warning(
            "Practical Assessment saved successfully. Now please upload the TRA Payment Receipt."
          );
          return;
        } else if (FileUploaded === true) {
          const formDataSubmission = new FormData();
          formDataSubmission.append("pointer_id", pointerData?.pointer_id);
          formDataSubmission.append("city_name", preferredLocation);

          try {
            setLoading(true);
            const response = await post_FinalSubmission_Of_stage4_API(
              pointerData?.pointer_id,
              formDataSubmission
            );
            setLoading(false);

            if (response?.data?.response?.response === true) {
              getPreFilledDetailsFunction();
              toast.success(
                "The practical assessment has been scheduled successfully."
              );

              navigate("/user/view_application", { state: { pointerData } });
            } else {
              toast.error(
                response?.data?.response?.error_msg ||
                  "Failed to save Practical Assessment"
              );
            }
          } catch (error) {
            setLoading(false);
            console.error("Error in saving Practical Assessment:", error);
            toast.error("Failed to save Practical Assessment");
          }

          return;
        }
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Practical Assessment"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in saving Practical Assessment:", error);
      toast.error("Failed to save Practical Assessment");
    }
  };

  const handleSavingAndNext = async (e) => {
    e.preventDefault();

    const formattedPaymentDate =
      paymentDate &&
      `${paymentDate.getDate()}/${
        paymentDate.getMonth() + 1
      }/${paymentDate.getFullYear()}`;

    if (preferredLocation.trim() === "") {
      toast.error("Please select practical interview location.");
      document.getElementById("preferredLocationID").focus();
      return;
    }

    // if (comments.trim() === "") {
    //   toast.error("Please select practical interview location.");
    //   document.getElementById("commentID").focus();
    //   return;
    // }

    if (receiptNumber.trim() === "") {
      toast.error("Please select TRA Payment Receipt Number.");
      document.getElementById("RecieptNumberID").focus();
      return;
    }

    if (paymentDate === null) {
      toast.error("Please select Payment Date");
      document.getElementById("paymentDate").focus();
      return;
    }

    // if (FileUploaded === false) {
    //   toast.error("Please upload the TRA Payment Receipt.");
    //   return;
    // }

    // const formData = new FormData();
    // formData.append("pointer_id", pointerData?.pointer_id);
    // formData.append("offline_location_id", preferredLocationID);
    // formData.append("preference_location", preferredLocation);
    // formData.append("preference_comment", comments);
    // formData.append("recipt_number", receiptNumber);
    // formData.append("payment_date", formattedPaymentDate);

    const safeValue = (value) =>
      value == null ||
      value === "" ||
      value === "undefined" ||
      value === undefined
        ? ""
        : value;

    const formData = new FormData();
    formData.append("pointer_id", safeValue(pointerData?.pointer_id));
    formData.append("offline_location_id", safeValue(preferredLocationID));
    formData.append("preference_location", safeValue(preferredLocation));
    formData.append("preference_comment", safeValue(comments));
    formData.append("recipt_number", safeValue(receiptNumber));
    formData.append("payment_date", safeValue(formattedPaymentDate));

    try {
      setLoading(true);
      const response = await post_Practical_Interview_stage4_API(
        pointerData?.pointer_id,
        formData
      );
      setLoading(false);

      if (response?.data?.response?.response === true) {
        getPreFilledDetailsFunction();

        if (FileUploaded === false) {
          toast.warning(
            "Practical Assessment saved successfully. Now please upload the TRA Payment Receipt."
          );
          return;
        } else if (FileUploaded === true) {
          toast.success("Practical Assessment saved successfully.");
          navigate("/user/view_application", { state: { pointerData } });

          return;
        }
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Practical Assessment"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in saving Practical Assessment:", error);
      toast.error("Failed to save Practical Assessment");
    }
  };

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const handleConfirmationBackToDashboardNo = () => {
    setShowConfirmationBackToDashboardModal(false);
  };

  const handleConfirmationBackToDashboardYes = () => {
    // navigate("/user/dashboard");
    // setShowConfirmationBackToDashboardModal(false);
    // toast.success("Navigated to Dashboard.");

    navigate("/user/view_application", {
      state: { pointerData },
    });
    setShowConfirmationBackToDashboardModal(false);
    toast.success("Navigated to View Application.");
  };

  const handleBackToView = () => {
    setBackToDashboardButtonClicked(true);
    setShowConfirmationBackToDashboardModal(true);
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const getPreFilledDetailsFunction = async () => {
    try {
      setLoading(true);
      const response = await getAllDataOfStage_4_ByPointer_ID(
        pointerData?.pointer_id
      );
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.data
      ) {
        const stage_Details = response?.data?.response.data;

        if (stage_Details?.payment_date) {
          const originalIncomingStringForDate = stage_Details?.payment_date;

          // Split the string into parts
          const parts = originalIncomingStringForDate.split(" ")[0].split("-");

          // Parse the parts into integers
          const year = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
          const day = parseInt(parts[2], 10);

          // Create a Date object
          const modifiedDate = new Date(year, month, day);

          // Set state values
          setPaymentDate(modifiedDate);
        } else {
          setPaymentDate("");
        }

        // setPreferredLocation(stage_Details?.preference_location);
        // setAddress(stage_Details?.office_address);
        // setComments(stage_Details?.preference_comment);
        // setReceiptNumber(stage_Details?.receipt_number);
        // setApplicationNo(stage_Details?.unique_id);

        if (
          stage_Details?.preference_location === "" ||
          stage_Details?.preference_location === null||
          stage_Details?.preference_location === undefined
        ) {
          setPreferredLocation("");
          setPreferredLocationID("");
        } else if (
          stage_Details?.preference_location !== null ||
          stage_Details?.preference_location !== "" ||
          stage_Details?.preference_location !== undefined
        ) {
          setPreferredLocation(stage_Details?.preference_location);
          setPreferredLocationID(stage_Details?.offline_location_id);
        }

        if (
          stage_Details?.office_address === "" ||
          stage_Details?.office_address === null
        ) {
          setAddress("");
        } else if (
          stage_Details?.office_address !== null ||
          stage_Details?.office_address !== "" ||
          stage_Details?.office_address !== undefined
        ) {
          setAddress(stage_Details?.office_address);
        }

        if (
          stage_Details?.preference_comment === "" ||
          stage_Details?.preference_comment === null
        ) {
          setComments("");
        } else if (
          stage_Details?.preference_comment !== null ||
          stage_Details?.preference_comment !== "" ||
          stage_Details?.preference_comment !== undefined
        ) {
          setComments(stage_Details?.preference_comment);
        }

        if (
          stage_Details?.receipt_number === "" ||
          stage_Details?.receipt_number === null
        ) {
          setReceiptNumber("");
        } else if (
          stage_Details?.receipt_number !== null ||
          stage_Details?.receipt_number !== "" ||
          stage_Details?.receipt_number !== undefined
        ) {
          setReceiptNumber(stage_Details?.receipt_number);
        }

        if (
          stage_Details?.unique_id === "" ||
          stage_Details?.unique_id === null
        ) {
          setApplicationNo("");
        } else if (
          stage_Details?.unique_id !== null ||
          stage_Details?.unique_id !== "" ||
          stage_Details?.unique_id !== undefined
        ) {
          setApplicationNo(stage_Details?.unique_id);
        }

        if (stage_Details?.is_doc_uploaded === 0) {
          setFileUploaded(false);
        } else if (stage_Details?.is_doc_uploaded === 1) {
          setFileUploaded(true);
        }

        if (stage_Details?.popup_data?.length > 0) {
          setPopupData(stage_Details.popup_data);
          setNoteModal(true); // Show the first popup
        }

        // if (
        //     stage_Details?.payment_date !== "" &&
        //     stage_Details?.payment_date !== null &&
        //     stage_Details?.preference_location !== "" &&
        //     stage_Details?.office_address !== "" &&
        //     stage_Details?.receipt_number !== "" &&
        //     stage_Details?.is_doc_uploaded === 1
        //   ) {
        //     setSubmitButtonDisabled(false);
        //   } else {
        //     setSubmitButtonDisabled(true);
        //   }
      } else {
        console.error(
          "Error fetching stage 4 details data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching stage 4 details data:", error);
    }
  };

  useEffect(() => {
    if (pointerData?.pointer_id) {
      getPreFilledDetailsFunction(pointerData.pointer_id);
    }
  }, [pointerData?.pointer_id]);

  useEffect(() => {
    // Show the next popup if it exists
    if (currentPopupIndex < popupData.length) {
      setNoteModal(true);
    }
  }, [currentPopupIndex, popupData]);

  const handleAutoSave = async () => {
    const formattedPaymentDate =
      paymentDate &&
      `${paymentDate.getDate()}/${
        paymentDate.getMonth() + 1
      }/${paymentDate.getFullYear()}`;

    const safeValue = (value) =>
      value == null ||
      value === "" ||
      value === "undefined" ||
      value === undefined
        ? ""
        : value;

    const formData = new FormData();
    formData.append("pointer_id", safeValue(pointerData?.pointer_id));
    formData.append("offline_location_id", safeValue(preferredLocationID));
    formData.append("preference_location", safeValue(preferredLocation));
    formData.append("preference_comment", safeValue(comments));
    formData.append("recipt_number", safeValue(receiptNumber));
    formData.append("payment_date", safeValue(formattedPaymentDate));

    try {
      // setLoading(true);
      const response = await post_Practical_Interview_stage4_API(
        pointerData?.pointer_id,
        formData
      );
      // setLoading(false);

      if (response?.data?.response?.response === true) {
        // getPreFilledDetailsFunction();
      } else {
        // toast.error(
        //   response?.data?.response?.error_msg ||
        //     "Failed to save Practical Assessment"
        // );
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error in saving Practical Assessment:", error);
      // toast.error("Failed to save Practical Assessment");
    }
  };
  useEffect(() => {
    handleAutoSave();
  }, [
    preferredLocationID,
    preferredLocation,
    comments,
    receiptNumber,
    paymentDate,
  ]);



  return (
    <>
      <Row>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBackToView}
            style={{
              color: "#055837",
              marginLeft: "25px",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <BsArrowLeft className="me-0" />
            Back to View Application
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Stage 4 - Practical Assessment" />
      </Row>

      <div className="practical-upload-container-contact-details">
        <Card className="shadow practical-upload-card-contact-details">
          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-1 mt-1">
                  Application No. :{" "}
                  <b style={{ color: "#055837" }}>
                    {/* {pointerData?.portal_refrance_no} */}
                    {ApplicationNo}
                  </b>
                </div>
              </div>
            </div>

            <Card.Body>
              <Form onSubmit={handleSavingAndNext}>
                {/* {loading && (
                  <div className="d-flex justify-content-center mt-3">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )} */}

                {loading && <Loader />}

                <div className="form-group">
                  <div className="row mb-4 mt-1 bg-offwhite">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="preferredLocationLabel">
                        <Form.Label
                          htmlFor="preferredLocationID"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          ▸ Preferred Practical Location{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>

                    <div className="col-md-7">
                      <Form.Group controlId="preferredLocationID">
                      <Form.Select
                            value={preferredLocation || ""}
                            style={{cursor:"pointer"}}
                            onChange={(e) => {
                              const country =
                                e.target.options[e.target.selectedIndex].dataset
                                  .country;
                              if (fetchLocationsByAPI[country]) {
                                const selectedLocation = fetchLocationsByAPI[
                                  country
                                ].find(
                                  (loc) => loc.city_name === e.target.value
                                );
                                if (selectedLocation) {
                                  handleChangeLocation(selectedLocation);
                                }
                              }
                            }}
                          >
                            <option
                              className="dropdown-options"
                              value=""
                              disabled
                            >
                              Select Location
                            </option>
                            {Object.keys(fetchLocationsByAPI).map((country) => (
                              <optgroup key={country} label={country}>
                                {fetchLocationsByAPI[country].map(
                                  (location) => (
                                    <option
                                      key={location.id}
                                      value={location.city_name}
                                      data-country={country}
                                    >
                                      {location.city_name} - {location.location}
                                    </option>
                                  )
                                )}
                              </optgroup>
                            ))}
                          </Form.Select>
                      </Form.Group>
                    </div>
                  </div>

                  {address && address !== "" && preferredLocation !== "" && preferredLocationID !== null && (
                    <div className="row bg-warning rounded p-2 text-size-control mb-4 ">
                      <div className="col-12"></div>
                      <div className="text-center d-flex justify-content-center align-items-center">
                        <strong
                          style={{ marginRight: "10px", minWidth: "70px" }}
                        >
                          Address:
                        </strong>
                        <div>{address}</div>
                      </div>
                    </div>
                  )}

                  <div className="row mb-5">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="commentLabel">
                        <Form.Label
                          htmlFor="commentID"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          ▸ Comments
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7">
                      <Form.Group controlId="commentID">
                        <Form.Control
                          as="textarea"
                          rows={6}
                          value={comments}
                          onChange={(e) => setComments(e.target.value)}
                          className="w-100 mx-auto"
                          placeholder="Examples: The applicant would need 2 weeks notice for the interview. The applicant is currently away, kindly schedule the interview after DD/MM/YYYY."
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row mb-5 bg-offwhite">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="RecieptNumberLabel">
                        <Form.Label
                          htmlFor="RecieptNumberID"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          ▸ TRA Payment Receipt Number{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7">
                      <Form.Group controlId="RecieptNumberID">
                        <Form.Control
                          type="number"
                          value={receiptNumber}
                          onChange={(e) => setReceiptNumber(e.target.value)}
                          required
                          placeholder="Enter TRA Payment Receipt Number"
                          className="w-100 mx-auto"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row mb-0">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="paymentDateLabel">
                        <Form.Label
                          htmlFor="paymentDate"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          ▸ Payment Date (dd/mm/yyyy){" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7">
                      <Form.Group controlId="paymentDateGroup">
                        <InputGroup>
                          <DatePicker
                            selected={paymentDate}
                            onChange={(date) => setPaymentDate(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select Payment Date"
                            className="form-control custom-cursor"
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={100}
                            id="paymentDate"
                            maxDate={new Date()}
                          />
                          <InputGroup.Text style={{ fontSize: "1rem" }}>
                            <BsCalendar size="0.7em" />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </div>

                  {FileUploaded ? (
                    <div className="docUploaded">
                      <div
                        className="shadow mt-0 p-4"
                        style={{
                          border: "1px solid #055837",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="accordion-header-wrapper">
                          <button
                            className="accordion-button"
                            type="button"
                            onClick={handleOpenUploadModal}
                            style={{
                              // border: "1px solid #055837",
                              color: "#055837",
                              borderRadius: "5px",
                            }}
                          >
                            <p className="accordion-header" id="h1eadingOne">
                              <div className="d-flex align-items-center">
                                <span style={{ marginRight: "10px" }}>
                                  <CheckCircleFill />
                                </span>
                                <span>
                                  TRA Payment Receipt File{" "}
                                  <span style={{ color: "red" }}>*</span>,
                                  Uploaded Successfully!
                                </span>
                              </div>
                            </p>
                          </button>
                          <span style={{ marginRight: "20px" }}>✔️</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="bg-white shadow mt-3 p-4 docNotUploaded"
                      style={{
                        border: "1px solid #ffcc01",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="accordion-header-wrapper">
                        <button
                          className="accordion-button"
                          type="button"
                          onClick={handleOpenUploadModal}
                        >
                          <p className="accordion-header" id="h1eadingOne">
                            <div className="d-flex align-items-center">
                              <span className="bullet-point"></span>
                              <span>
                                Select TRA Payment Receipt File{" "}
                                <span style={{ color: "red" }}>*</span>
                              </span>
                            </div>
                          </p>
                        </button>
                        <span style={{ marginRight: "20px" }}>⟫</span>
                      </div>
                    </div>
                  )}

                  <div style={{ marginTop: "15px" }}>
                    <UploadDocuments />
                  </div>

                  <hr className="mb-2 mt-2 thick-hr" />

                  {loading && (
                    <div className="d-flex justify-content-center mt-3">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  )}

                  {/* Save&Exit and Next Buttons */}
                  <div
                    className="button-group text-center mb-1"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    {/* Back and Save & Exit and Next Buttons */}
                    <div
                      className="button-group text-center mb-1"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      {/* Back Button */}
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          backgroundColor: "#ffcc01",
                          color: "#055837",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          border: "none",
                          borderRadius: "5px",
                          marginTop: "30px",
                          marginBottom: "20px",
                          transition: "background-color 0.3s, color 0.3s",
                        }}
                        onClick={handleBack}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#055837";
                          e.target.style.color = "#ffcc01";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#ffcc01";
                          e.target.style.color = "#055837";
                        }}
                      >
                        Back
                      </button>

                      {/* Save & Exit Button */}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSavingAndNext}
                        style={{
                          backgroundColor: "#055837",
                          color: "#ffcc01",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          border: "none",
                          borderRadius: "5px",
                          marginTop: "30px",
                          marginBottom: "20px",
                          transition: "background-color 0.3s, color 0.3s",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#ffcc01";
                          e.target.style.color = "#055837";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#055837";
                          e.target.style.color = "#ffcc01";
                        }}
                      >
                        Save & Exit
                      </button>

                      {/* Next Button */}
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={SubmitButtonDisabled}
                        style={{
                          backgroundColor: "#ffcc01",
                          color: "#055837",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                          border: "none",
                          borderRadius: "5px",
                          marginTop: "30px",
                          marginBottom: "20px",
                          transition: "background-color 0.3s, color 0.3s",
                        }}
                        onClick={handleNext}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#055837";
                          e.target.style.color = "#ffcc01";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "#ffcc01";
                          e.target.style.color = "#055837";
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card.Body>

          {backToDashboardButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackToDashboardModal}
              onHide={handleConfirmationBackToDashboardNo}
              onConfirm={handleConfirmationBackToDashboardYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the View Application page?"
            />
          )}

          {logoutClicked && (
            <ConfirmationModal
              show={showConfirmationModalForLogout}
              onHide={handleConfirmationLogoutNo}
              onConfirm={handleConfirmationLogoutYes}
              title="Confirm Exit"
              message="Are you sure you want to log out?"
            />
          )}

          {showUploadModal && (
            <UploadModal
              show={showUploadModal}
              onHide={handleCloseUploadModal}
              pointerData={pointerData}
              getUploadedDocsList={getPreFilledDetailsFunction}
              ModalHeading={"TRA Payment Receipt"}
              ModalID={"44"}
            />
          )}

          {LocationModal && (
            <LocationPopupModal
              show={handleOpenLocationModal}
              onHide={handleCloseLocationModal}
              title="Confirm Location"
              pointerData={pointerData}
              preferredLocation={preferredLocation}
              preferredLocationID={preferredLocationID}
              handleClearLocation={handleClearLocation}
              getPreFilledDetailsFunction={getPreFilledDetailsFunction}
            />
          )}

          {submitButtonClicked && (
            <ConfirmationModal
              show={showConfirmationSubmitModal}
              onHide={handleConfirmationSubmitNo}
              onConfirm={handleConfirmationSubmitYes}
              title="Confirm Submission"
              message="Are you sure you want to submit the application? You will not be able to remove or change these documents after submission?"
            />
          )}

          <div>
            {popupData.length > 0 && currentPopupIndex < popupData.length && (
              <NotePopup
                show={NoteModal}
                onHide={handleCloseNoteModal}
                title="Important!"
                matter={popupData[currentPopupIndex].data}
              />
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default PracticalAssessment;
