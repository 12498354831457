import React from "react";
import { Modal, Button } from "react-bootstrap";
import { BiBell } from "react-icons/bi";

const NotificationsModal = ({
  showNotificationsModal,
  closeNotificationsModal,
}) => {
  return (
    <>
      <Modal show={showNotificationsModal} centered>
        <Modal.Header>
          <Modal.Title
            className="text-center w-100 d-flex align-items-center justify-content-center"
            style={{
              fontSize: "18px",
              fontFamily: "Arial, sans-serif",
              color: "#055837",
            }}
          >
            <BiBell className="me-2" />
            Notifications !
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="text-left"
          style={{ fontSize: "16px", fontFamily: "Arial, sans-serif" }}
        >
          <ul style={{ listStyle: "none", paddingLeft: "0" }}>
            <li style={{ marginBottom: "10px" }}>
              <span style={{ color: "black" }}>•</span>
              {"  "}
              <strong>Oops...!</strong>, you don't have any new updates /
              notifications yet.
            </li>

            {/* <li style={{ marginBottom: "10px" }}>
              <span style={{ color: "black" }}>•</span> An email has been sent
              to  address (<strong>{"email"}</strong>)
            </li> */}
            
          </ul>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="light"
            onClick={closeNotificationsModal}
            className="w-50"
            style={{
              maxWidth: "130px",
              backgroundColor: "#055837",
              color: "#ffcc01",
              transition: "transform 0.2s",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotificationsModal;
