import React from "react";
import { useLocation } from "react-router-dom";
import "./Stepper.css";
import { FaCheck } from "react-icons/fa"; 

const Stepper = () => {
  const location = useLocation();
  const currentStep = getCurrentStep(location.pathname);

  return (
    <div className="stepper">
      {Array.from({ length: 9 }, (_, i) => (
        <Step key={i + 1} step={i + 1} currentStep={currentStep} />
      ))}
    </div>
  );
};

const getCurrentStep = (path) => {
  const match = path.match(/stage_1\/([a-zA-Z_]+)/);
  if (match && match[1]) {
    switch (match[1]) {
      case "occupation_details":
        return 1;
      case "personal_details":
        return 2;
      case "contact_details":
        return 3;
      case "identification":
        return 4;
      case "usi_and_avetmiss":
        return 5;
      case "education_employment":
        return 6;
      case "review_and_confirm":
        return 7;
      case "application_declaration":
        return 8;
      case "upload_documents":
        return 9;
      default:
        return 1;
    }
  }
  return 1;
};

const Step = ({ step, currentStep }) => {
  const isActive = step <= currentStep;
  const isCurrent = step === currentStep;
  const isPreviousStep = step < currentStep;
  const circleStyle = {
    backgroundColor: isPreviousStep ? "#ffcc01" : isActive ? "#fff" : "#fff",
  };

  return (
    <div className={`step ${isActive ? "active" : ""} ${isCurrent ? "current" : ""} ${step < currentStep ? "before-current" : ""}`}>
      <div className="step-circle" style={circleStyle}>
        {step}
      </div>
      <span className={`step-text ${isCurrent ? 'current-text' : ''}`}>
        {getStepText(step)}
        {" "} 
        {isPreviousStep && <FaCheck size={13} style={{ color: '#055837' }} />}
      </span>
    </div>
  );
};

// Function to get the step text based on step number
const getStepText = (step) => {
  switch (step) {
    case 1:
      return "Occupation";
    case 2:
      return "Personal Details";
    case 3:
      return "Contact Details";
    case 4:
      return "Identification";
    case 5:
      return "USI & Avetmiss";
    case 6:
      return "Education & Employment";
    case 7:
      return "Review & Confirm";
    case 8:
      return "Applicant Declaration";
    case 9:
      return "Upload Documents";
    default:
      return "";
  }
};

export default Stepper;
