import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Occupation Details.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputGroup } from "react-bootstrap";
import { BsCalendar } from "react-icons/bs";

import {
  getAllOccupationsAPI,
  createOccupationDetails_stage1_API,
  getOccupationDetails_stage1_API,
} from "../../../../api";
import { useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";

const OccupationDetails = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const pointerData = props.pointerData || location.state?.pointerData;

  const [occupations, setOccupations] = useState([]);
  const [occupation, setOccupation] = useState("");

  useEffect(() => {
    // Fetch occupations when component mounts
    const fetchOccupations = async () => {
      try {
        const response = await getAllOccupationsAPI();
        if (response.data?.response && response.data?.response?.data) {
          setOccupations(response.data.response.data);
        } else {
          console.error(
            "Error fetching occupations:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching occupations:", error);
      }
    };

    fetchOccupations();
  }, []);

  const [skillsAssessmentProgram, setSkillsAssessmentProgram] = useState("");
  const [pathway, setPathway] = useState("");
  const [inAustralia, setInAustralia] = useState("");
  const [inBoardingVisa, setInBoardingVisa] = useState("");
  const [intendedVisa, setIntendedVisa] = useState("");

  const [currentVisaCategoryAndSubclass, setCurrentVisaCategoryAndSubclass] =
    useState("");
  const [visaExpiryDate, setVisaExpiryDate] = useState(null);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleNext = async () => {
    const formattedVisaExpiryDate =
      visaExpiryDate &&
      `${visaExpiryDate.getDate()}/${
        visaExpiryDate.getMonth() + 1
      }/${visaExpiryDate.getFullYear()}`;

    if (occupation.trim() === "") {
      document.getElementById("occupationSelect").focus();
      toast.error("Please select an occupation.");
      return;
    }

    if (skillsAssessmentProgram.trim() === "") {
      toast.error("Please select a skills assessment program.");
      document.getElementById("skillsAssessmentProgramRadio").focus();
      return;
    }

    if (pathway.trim() === "") {
      toast.error("Please select a pathway.");
      document.getElementById("pathwayRadio").focus();
      return;
    }

    if (inAustralia.trim() === "") {
      toast.error("Please specify if you are currently in Australia.");
      document.getElementById("inAustraliaRadio").focus();
      return;
    } else if (inAustralia === "yes" && inBoardingVisa.trim() === "") {
      toast.error("Please specify if you are currently on a bridging visa.");
      document.getElementById("inBoardingVisaRadio").focus();
      return;
    } else if (inAustralia === "yes" && inBoardingVisa === "no") {
      if (!currentVisaCategoryAndSubclass.trim()) {
        document.getElementById("currentVisaCategoryAndSubclassInput").focus();
        toast.error("Please enter the current visa category & subclass.");
        return;
      }
      if (visaExpiryDate === null) {
        document.getElementById("visaExpiryDatePicker").focus();
        toast.error("Please select the visa expiry date.");
        return;
      }
    }

    if (intendedVisa.trim() === "") {
      toast.error("Please enter the intended visa.");
      document.getElementById("intendedVisaInput").focus();
      return;
    }

    // Navigate to personal details
    // navigate("/user/create_new_application/stage_1/personal_details");
    // navigate('/user/create_new_application/stage_1/personal_details', { state: { pointerData } });
    // toast.success("Occupation Details saved successfully.");

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("pointer_id", pointerData?.pointer_id);
      formData.append("currently_in_australia", inAustralia);
      formData.append("currently_on_bridging_visa", inBoardingVisa);
      formData.append("visa_expiry", formattedVisaExpiryDate);
      formData.append("occupation_id", occupation);
      formData.append("program", skillsAssessmentProgram);
      formData.append("pathway", pathway);
      formData.append("current_visa_category", currentVisaCategoryAndSubclass);
      formData.append("conjunction_with_skills_assessment", intendedVisa);

      const response = await createOccupationDetails_stage1_API(formData);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        navigate("/user/create_new_application/stage_1/personal_details", {
          state: { pointerData },
        });
        toast.success("Occupation Details saved successfully.");
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Occupation Details"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saving Occupation Details:", error);
      toast.error("Failed to save Occupation Details");
    }
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = () => {
    navigate("/user/dashboard");
    setShowConfirmationModal(false);
    // toast.success("Occupation Details saved successfully.");
  };

  const handleSaveAndExit = async () => {
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    const getOccupationDetailsFunction = async () => {
      try {
        setLoading(true);

        const response = await getOccupationDetails_stage1_API(
          pointerData?.pointer_id
        );
        setLoading(false);

        if (
          response &&
          response?.data &&
          response?.data?.response &&
          response?.data?.response?.data
        ) {
          const stage_1_occupation =
            response?.data?.response?.data?.stage_1_occupation;

          const originalIncomingString = stage_1_occupation.visa_expiry;

          // Split the string into parts
          const parts = originalIncomingString.split("-");

          // Parse the parts into integers
          const year = parseInt(parts[0]);
          const month = parseInt(parts[1]) - 1;
          const day = parseInt(parts[2]);

          // Create a Date object
          const modifiedDate = new Date(year, month, day);

          if (stage_1_occupation.visa_expiry === "") {
            setVisaExpiryDate(null);
          } else {
            setVisaExpiryDate(modifiedDate);
          }

          setInAustralia(stage_1_occupation.currently_in_australia);
          setInBoardingVisa(stage_1_occupation.currently_on_bridging_visa);

          if (stage_1_occupation.occupation_id === "0") {
            setOccupation("");
          } else {
            setOccupation(stage_1_occupation.occupation_id);
          }

          setSkillsAssessmentProgram(stage_1_occupation.program);
          setPathway(stage_1_occupation.pathway);
          setCurrentVisaCategoryAndSubclass(
            stage_1_occupation.current_visa_category
          );
          setIntendedVisa(
            stage_1_occupation.conjunction_with_skills_assessment
          );
        } else {
          setLoading(false);

          console.error(
            "Error fetching occupations data:",
            response?.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);

        console.error("Error fetching occupations data:", error);
      }
    };

    if (pointerData?.pointer_id) {
      getOccupationDetailsFunction(pointerData?.pointer_id);
    }
  }, [pointerData?.pointer_id]);

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="forgot-container-new-application">
        <Card className="shadow forgot-card-new-application">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Occupation Details</b>
          </div>
          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-3 mt-1">
                  Portal Reference No. :{" "}
                  {/* <b style={{ color: "#055837" }}>24AQ063</b> */}
                  <b style={{ color: "#055837" }}>
                    {pointerData?.portal_refrance_no}
                  </b>
                </div>
              </div>
            </div>

            <Form>
              <div className="account-details">
                <div className="row mb-3 mt-1 bg-offwhite">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="occupationLabel">
                      <Form.Label
                        htmlFor="occupationSelect"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Choose Occupation{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7">
                    <Form.Group controlId="occupationSelect">
                      <Form.Select
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                        style={{ cursor: "pointer" }}
                      >
                        <option
                          className="dropdown-options"
                          value=""
                          disabled
                          selected
                        >
                          Select Occupation
                        </option>
                        {/* Map over occupations to generate options */}
                        {occupations.map((occupation) => (
                          <option
                            key={occupation.id}
                            className="dropdown-options"
                            value={occupation.id}
                          >
                            {occupation.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="skillsAssessmentProgramLabel">
                      <Form.Label
                        htmlFor="skillsAssessmentProgramRadio"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Skills Assessment Program{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7 d-flex align-items-center">
                    <Form.Group
                      controlId="skillsAssessmentProgramRadio"
                      className="d-flex"
                    >
                      <Form.Check
                        type="radio"
                        label="TSS"
                        value="TSS"
                        id="skillsAssessmentProgramRadioTss"
                        name="skillsAssessmentProgram"
                        className="me-5 radio-with-border"
                        checked={skillsAssessmentProgram === "TSS"}
                        onChange={(e) =>
                          setSkillsAssessmentProgram(e.target.value)
                        }
                        style={{
                          cursor: "pointer",
                          color:
                            skillsAssessmentProgram === "TSS" ? "#055837" : "",
                        }}
                      />
                      <Form.Check
                        type="radio"
                        label="OSAP"
                        value="OSAP"
                        id="skillsAssessmentProgramRadioOsap"
                        name="skillsAssessmentProgram"
                        className="me-5 radio-with-border"
                        checked={skillsAssessmentProgram === "OSAP"}
                        onChange={(e) =>
                          setSkillsAssessmentProgram(e.target.value)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="row mb-3 bg-offwhite">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="pathwayLabel">
                      <Form.Label
                        htmlFor="pathwayRadio"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Pathway <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>

                  <div className="col-md-7 d-flex align-items-center">
                    <Form.Group
                      controlId="pathwayRadio"
                      className="d-flex flex-column"
                    >
                      <div className="form-check mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="pathwayRadio"
                          id="pathwayRadio1"
                          value="Pathway 1"
                          checked={pathway === "Pathway 1"}
                          onChange={(e) => setPathway(e.target.value)}
                          style={{
                            border: "1px solid black",
                            borderRadius: "50%",
                            marginRight: "5px",
                            cursor: "pointer",
                          }}
                        />

                        <label
                          className="form-check-label"
                          htmlFor="pathwayRadio"
                        >
                          Pathway 1
                        </label>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "lighter",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          <span className="pathway-highlight-light-highlight-bg">
                            DO NOT
                          </span>{" "}
                          hold an Australian AQF III Qualification or (AQF IV
                          for Chef) in the occupation area.
                        </div>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="pathwayRadio"
                          id="pathwayRadio2"
                          value="Pathway 2"
                          checked={pathway === "Pathway 2"}
                          onChange={(e) => setPathway(e.target.value)}
                          style={{
                            border: "1px solid black",
                            borderRadius: "50%",
                            marginRight: "5px",
                            cursor: "pointer",
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="pathwayRadio"
                        >
                          Pathway 2
                        </label>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "lighter",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          Currently hold an Australian AQF III Qualification or
                          (AQF IV for Chef), in the occupation area.
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="inAustraliaLabel">
                      <Form.Label
                        htmlFor="inAustraliaRadio"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Are you currently in Australia ?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7 d-flex align-items-center">
                    <Form.Group controlId="inAustraliaRadio" className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Yes"
                        value="yes"
                        id="inAustraliaRadioYes"
                        name="inAustraliaRadio"
                        className="me-5  radio-with-border"
                        checked={inAustralia === "yes"}
                        onChange={(e) => setInAustralia(e.target.value)}
                        style={{ cursor: "pointer" }}
                      />
                      <Form.Check
                        type="radio"
                        label="No"
                        value="no"
                        id="inAustraliaRadioNo"
                        className="me-5  radio-with-border"
                        name="inAustraliaRadio"
                        checked={inAustralia === "no"}
                        onChange={(e) => setInAustralia(e.target.value)}
                        style={{ cursor: "pointer" }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {inAustralia === "yes" && (
                  <div className="row mb-3 bg-offwhite">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="inBoardingVisaLabel">
                        <Form.Label
                          htmlFor="inBoardingVisaRadio"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          ▸ Are you currently on a bridging Visa ?{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7 d-flex align-items-center">
                      <Form.Group
                        controlId="inBoardingVisaRadio"
                        className="d-flex"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          value="yes"
                          id="inBoardingVisaRadioYes"
                          name="inBoardingVisaRadio"
                          className="me-5 radio-with-border"
                          checked={inBoardingVisa === "yes"}
                          onChange={(e) => setInBoardingVisa(e.target.value)}
                          style={{ cursor: "pointer" }}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          value="no"
                          id="inBoardingVisaRadioNo"
                          name="inBoardingVisaRadio"
                          className="me-5 radio-with-border"
                          checked={inBoardingVisa === "no"}
                          onChange={(e) => setInBoardingVisa(e.target.value)}
                          style={{ cursor: "pointer" }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}

                {inAustralia === "yes" && inBoardingVisa === "no" && (
                  <>
                    {/* Current Visa Category & Subclass */}
                    <div className="row mb-3 bg-offwhite">
                      <div className="col-md-5 d-flex align-items-center justify-content-start">
                        <Form.Group controlId="currentVisaCategoryAndSubclassLabel">
                          <Form.Label
                            htmlFor="currentVisaCategoryAndSubclassInput"
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            ▸ Current Visa Category & Subclass{" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                        </Form.Group>
                      </div>
                      <div className="col-md-7">
                        <Form.Group controlId="currentVisaCategoryAndSubclassInput">
                          <Form.Control
                            type="text"
                            placeholder="Enter current visa category & subclass"
                            value={currentVisaCategoryAndSubclass}
                            onChange={(e) =>
                              setCurrentVisaCategoryAndSubclass(e.target.value)
                            }
                          />
                        </Form.Group>
                      </div>
                    </div>

                    {/* Visa Expiry Date (dd/mm/yyyy) */}
                    <div className="row ">
                      <div className="col-md-5 d-flex align-items-center justify-content-start">
                        <Form.Group controlId="visaExpiryDateLabel">
                          <Form.Label
                            htmlFor="visaExpiryDatePicker"
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            ▸ Visa Expiry Date (dd/mm/yyyy){" "}
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                        </Form.Group>
                      </div>

                      <div className="col-md-7">
                        <Form.Group controlId="visaExpiryDatePicker">
                          <InputGroup style={{ cursor: "pointer" }}>


{/* 
                          <input
                          type="date"
                          id="visaExpiryDatePicker"
                          selected={visaExpiryDate}
                          onChange={(date) => setVisaExpiryDate(date)}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select VISA expiry date"
                          className="form-control"
                          style={{ cursor: "pointer", zIndex: "999" }}
                          minDate={new Date()}
                        /> */}


                            <DatePicker
                              id="visaExpiryDatePicker"
                              selected={visaExpiryDate}
                              onChange={(date) => setVisaExpiryDate(date)}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="Select VISA expiry date"
                              className="form-control"
                              showYearDropdown
                              scrollableYearDropdown
                              yearDropdownItemNumber={
                                150 - (currentYear - 2000)
                              } 
                              minDate={new Date()} 
                              style={{ cursor: "pointer", zIndex: "999" }}
                        type="date"

                            />


                            
                            <InputGroup.Text
                              onClick={() =>
                                document
                                  .getElementById("visaExpiryDatePicker")
                                  .click()
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <BsCalendar size="0.7em" />
                            </InputGroup.Text>




                          </InputGroup>
                        </Form.Group>
                      </div>
                    </div>
                  </>
                )}

                <div className="row mb-1 bg-offwhite">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="intendedVisaLabel">
                      <Form.Label
                        htmlFor="intendedVisaInput"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                          // fontSize: "14px",
                        }}
                      >
                        ▸ Which visa do you intend to apply for in conjunction
                        with this skills assessment ?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7 d-flex align-items-center">
                    <Form.Group controlId="intendedVisaInput" className="w-100">
                      <Form.Control
                        type="text"
                        placeholder="Enter your intended visa"
                        style={{ textAlign: "left", paddingLeft: "10px" }}
                        value={intendedVisa}
                        onChange={(e) => setIntendedVisa(e.target.value)}
                        className="w-100"
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              {/* {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )} */}

              
{loading && (
 <Loader />
      )}



              {/* Save&Exit and Next Buttons */}
              <div
                className="button-group text-center mb-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {/* Save&Exit and Next Buttons */}
                <div
                  className="button-group text-center mb-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {/* Save & Exit Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveAndExit}
                    style={{
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                  >
                    Save & Exit
                  </button>

                  {/* Next Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleNext}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </Form>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Leaving"
            message="Are you sure you want to leave this page? You don't want to continue this new application process right now? If you proceed, you'll be redirected to the dashboard."
          />
        </Card>
      </div>
    </>
  );
};

export default OccupationDetails;
