import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import "./Upload Modal.css";
import { XCircleFill, CheckCircleFill } from "react-bootstrap-icons";
import ConfirmationModal from "../../../../Confirmation Modal/ConfirmationModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  uploadDocuments_stage3_R_API,
  deleteDocumentAPI,
  getDocumentsDetailsAPI,
} from "../../../../../api";
import sampleImage from "../OffShore Sample Images/samPDF.jpeg";
import Loader from "../../../../Loader/Loader";

const UploadModal = ({
  show,
  onHide,
  pointerData,
  getUploadedDocsList,
  ModalHeading,
  ModalID,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [cancelClicked, setCancelClicked] = useState(false);

  const [selectedFileName, setSelectedFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [doc_iddddd, setDoc_iddddd] = useState(null);
  const [file_URL, setFile_URL] = useState(null);

  const required_document_id = ModalID;

  const getDocumentDetailsFunction = async () => {
    try {
      setLoading(true);
      const response = await getDocumentsDetailsAPI(
        pointerData?.pointer_id,
        required_document_id,
        'stage_3_R'
      );
      setLoading(false);

      if (
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        const uploadedFileDetails = response?.data?.response?.data;

        if (
          uploadedFileDetails?.required_document_id === required_document_id
        ) {
          setDoc_iddddd(uploadedFileDetails?.id);
          setFile_URL(uploadedFileDetails?.document_path);
          setUploadSuccess(true);
          getUploadedDocsList();
        } else {
          console.error("Document not found in the response data.");
        }
      } else {
        console.error(
          "Error fetching document details:",
          response?.data?.response?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching document details:", error);
    }
  };

  useEffect(() => {
    if (show) {
      getDocumentDetailsFunction();
    }
  }, [show]);

  // Function to handle image selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFileName(file.name);
  };

  // Function to handle uploading the image
  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select the file.");
      return;
    }

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("document_to_upload", selectedFile);
    formData.append("name", ModalHeading);
    formData.append("required_document_id", required_document_id);
    formData.append("stage", "stage_3_R");
    formData.append("status", "1");

    try {
      setLoading(true);

      const response = await uploadDocuments_stage3_R_API(
        pointerData?.pointer_id,
        required_document_id,
        formData
      );

      setLoading(false);

      if (response?.data?.response?.response === true) {
        setUploadSuccess(true);
        getDocumentDetailsFunction(
          pointerData?.pointer_id,
          required_document_id
        );
        getUploadedDocsList();
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg || "Failed to upload file."
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Failed to upload file.", error);
      toast.error("Failed to upload file.");
    }

    // setUploadSuccess(true);
  };

  const [showConfirmationCancelModal, setShowConfirmationCancelModal] =
    useState(false);

  const handleConfirmationBackNo = () => {
    setShowConfirmationCancelModal(false);
  };

  const handleConfirmationBackYes = async () => {
    try {
      setLoading(true);

      const response = await deleteDocumentAPI(
        pointerData?.pointer_id,
        doc_iddddd
      );

      setLoading(false);

      if (response?.data?.response?.response === true) {
        setShowConfirmationCancelModal(false);
        toast.success("File has been deleted successfully.");
        setSelectedFile(null);
        setUploadSuccess(false);
        setCancelClicked(true);
        getUploadedDocsList();

        getDocumentDetailsFunction(
          pointerData?.pointer_id,
          required_document_id
        );
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg || "Failed to delete file."
        );
        setShowConfirmationCancelModal(false);
      }
    } catch (error) {
      setLoading(false);
      setShowConfirmationCancelModal(false);

      console.error("Failed to delete file.", error);
      toast.error("Failed to delete file.");
    }

    // setShowConfirmationCancelModal(false);
    // toast.success("File has been deleted successfully.");
    // setSelectedFile(null);
    // setUploadSuccess(false);
    // setCancelClicked(true);
  };

  const handleConfirmationModal = () => {
    setCancelClicked(true);
    setShowConfirmationCancelModal(true);
    getUploadedDocsList();
  };

  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title
          className="text-center"
          style={{
            fontSize: "18px",
            fontFamily: "Arial, sans-serif",
            color: "#055837",
          }}
        >
          Upload {ModalHeading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!uploadSuccess ? (
          <div className="row">
            <div className="col-7">
              <Form.Group controlId="formFile" className="mb-2 mt-1">
                <Form.Label> ▸ Select a file:</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFileSelect}
                  accept=".pdf"
                  style={{ height: "35px" }}
                />
                <div className="text-danger text-size-control-extensions">
                  Only: .pdf
                </div>
              </Form.Group>
            </div>

            {/* Display selected image preview */}
            <div className="col-5 mt-1 d-flex align-items-center justify-content-center text-size-control">
              {cancelClicked && !selectedFile && (
                <div>
                  <span>▸ File Deleted Successfully !</span>
                  <br></br>
                  <span>▸ Please choose another file.</span>
                </div>
              )}

              {!selectedFile && file_URL === null && !cancelClicked && (
                <div className="text-center text-size-control">
                  <p>Sample File Preview:</p>

                  {/* <img
                    src={
                  sampleImage
                    }
                    alt="File Preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100px",
                      height: "auto",
                    }}
                  /> */}

                  <iframe
                    src={sampleImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      overflow: "auto",
                    }}
                    title="Sample File Preview:"
                  />
                </div>
              )}

              {selectedFile && (
                <div className="text-center text-size-control">
                  <p>Selected File Preview:</p>
                  <div style={{ width: "100%", height: "100%" }}>
                    <iframe
                      src={
                        file_URL !== null
                          ? file_URL
                          : URL.createObjectURL(selectedFile)
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        overflow: "auto",
                      }}
                      title="Selected Preview"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-7 mt-5">
                <p
                  style={{ color: "#055837", marginBottom: "5px" }}
                  className="p-1 text-size-control-extensions"
                >
                  File Uploaded successfully{" "}
                  <CheckCircleFill className="me-1" />
                </p>

                <div className="progress" style={{ height: "15px" }}>
                  <div
                    className="progress-bar d-flex align-items-center justify-content-center"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuemax="100"
                  >
                    100%
                  </div>
                </div>

                <div className="d-flex align-items-center mt-2">
                  <p className="mb-0 mt-1 me-2 text-size-control-extensions">
                    Do you want to remove selected file ?
                  </p>
                  <div>
                    <Button
                      variant="link"
                      onClick={handleConfirmationModal}
                      className="text-danger p-0"
                      style={{ marginLeft: "7px" }}
                    >
                      <div className="square-button">
                        {/* <XCircleFill size={20} /> */}
                        Yes
                      </div>
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-5  mt-1 d-flex align-items-center justify-content-center">
                {file_URL !== null && (
                  <div className="text-center text-size-control">
                    <p>Uploaded File Preview:</p>
                    <div style={{ width: "100%", height: "100%" }}>
                      <iframe
                        src={
                          file_URL !== null
                            ? file_URL
                            : URL.createObjectURL(selectedFile)
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "none",
                          overflow: "auto",
                        }}
                        title="Selected Preview"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
{/* 
      {loading && (
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )} */}

{loading && (
 <Loader />
      )}

      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="light"
          onClick={onHide}
          className="w-50"
          style={{
            maxWidth: "130px",
            backgroundColor: "#dc3545",
            color: "white",
            transition: "transform 0.1s",
          }}
          onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
          onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
        >
          Close
        </Button>

        {!uploadSuccess && (
          <Button
            variant="light"
            onClick={handleUpload}
            className="w-50"
            style={{
              maxWidth: "130px",
              backgroundColor: "#055837",
              color: "#ffcc01",
              transition: "transform 0.1s",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Upload <i className="bi bi-upload"></i>
          </Button>
        )}
      </Modal.Footer>

      {cancelClicked && (
        <ConfirmationModal
          show={showConfirmationCancelModal}
          onHide={handleConfirmationBackNo}
          onConfirm={handleConfirmationBackYes}
          title="Confirm Delete"
          message="Are you sure you want to remove this file?"
        />
      )}
    </Modal>
  );
};

export default UploadModal;
