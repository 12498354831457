import React, { useState, useEffect } from "react";
import "./Employment Documents.css";
import { useLocation } from "react-router-dom";
import { CheckCircleFill } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Form, Spinner } from "react-bootstrap";

import UploadModal from "./Upload Modal/Upload Modal";
import Others from "./Others/Others";

import {
  getuploadedDocumentsList_stage2_Employment_API,
  stage2_get_employment_docs_names,
  stage2_get_organisations_names,
} from "../../../../../api";
import ImgPdfUpload from "./ImgPdf Upload/ImgPdf Upload";

const EmploymentDocuments = ({ CheckUploadedForEmploymentDocs }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [EmploymentDocsList, setEmploymentDocsList] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [selectCompanyOrganisationID, setSelectCompanyOrganisationID] =
    useState("");
  const [SelectCompanyOrganisationName, setSelectCompanyOrganisationName] =
    useState("");
  const [companiesOrOrganisations, setCompaniesOrOrganisations] = useState([]);

  const handleCompanyOrganisationChange = (e) => {
    const selectedCompanyId = e.target.value;
    const selectedCompany = companiesOrOrganisations.find(
      (company) => company.id === selectedCompanyId
    );

    if (selectedCompany) {
      setSelectCompanyOrganisationID(selectedCompany.id);
      setSelectCompanyOrganisationName(
        selectedCompany.company_organisation_name
      );
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await stage2_get_organisations_names(
          pointerData?.pointer_id
        );
        if (
          response.data?.response &&
          response.data?.response?.employment_data
        ) {
          setCompaniesOrOrganisations(response.data.response.employment_data);
        } else {
          console.error(
            "Error fetching occupations:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching occupations:", error);
      }
    };

    if (pointerData?.pointer_id) {
      fetchCompanies();
    }
  }, [pointerData?.pointer_id]);

  useEffect(() => {
    const fetchEmploymentList = async () => {
      try {
        const response = await stage2_get_employment_docs_names(
          pointerData?.pointer_id,
          selectCompanyOrganisationID
        );
        if (response?.data?.response && response?.data?.response?.data) {
          setEmploymentDocsList(response?.data?.response?.data);
        } else {
          console.error(
            "Error fetching assessment documents:",
            response?.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching assessment documents:", error);
      }
    };

    if (pointerData?.pointer_id && selectCompanyOrganisationID) {
      fetchEmploymentList();
    }
  }, [pointerData?.pointer_id, selectCompanyOrganisationID]);

  const getUploadedDocsList = async () => {
    try {
      setLoading(true);
      const response = await getuploadedDocumentsList_stage2_Employment_API(
        pointerData?.pointer_id,
        selectCompanyOrganisationID
      );
      setLoading(false);
      if (response && response?.data && response?.data?.response) {
        setUploadedDocs(response?.data?.response?.data);
      } else {
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (pointerData?.pointer_id && selectCompanyOrganisationID) {
      getUploadedDocsList();
    }
  }, [pointerData?.pointer_id, selectCompanyOrganisationID]);

  const handleOpenPopupModal = (doc) => {
    setCurrentDoc(doc);
    setShowUploadModal(true);
  };

  const handleClosePopupModal = () => {
    setShowUploadModal(false);
    setCurrentDoc(null);
  };

  const isDocUploaded = (docId) => {
    const doc = uploadedDocs.find((item) => item.id === docId);
    return doc && doc.status === 1;
  };

  const handleCheckUploadedOrNot = () => {
    if (selectCompanyOrganisationID.trim() === "") {
      toast.error(
        "Please Select Company / Organisation for Employment Documents"
      );
      document.getElementById("selectCompanyOrganisation").focus();
      return;
    } 
  };

  useEffect(() => {
    if (CheckUploadedForEmploymentDocs) {
      handleCheckUploadedOrNot();
    }
  }, [CheckUploadedForEmploymentDocs]);

  const renderUploadModal = (doc) => {
    const allowedTypes = JSON.parse(doc?.allowed_type || "[]");

    if (allowedTypes.length === 1 && allowedTypes.includes("pdf")) {
      return (
        <UploadModal
          show={showUploadModal && currentDoc?.id === doc?.id}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={doc?.document_name}
          ModalID={doc?.id}
          selectCompanyOrganisationID={selectCompanyOrganisationID}
          SelectCompanyOrganisationName={SelectCompanyOrganisationName}
          ModalNote ={doc?.note}
        />
      );
    } else if (allowedTypes.length === 3 && allowedTypes.includes("pdf") && allowedTypes.includes("jpeg")) {
      return (
        <ImgPdfUpload
          show={showUploadModal && currentDoc?.id === doc?.id}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={doc?.document_name}
          ModalID={doc?.id}
          selectCompanyOrganisationID={selectCompanyOrganisationID}
          SelectCompanyOrganisationName={SelectCompanyOrganisationName}
          ModalNote = {doc?.note}
        />
      );
    } else {
      return (
        <Others
          show={showUploadModal && currentDoc?.id === doc?.id}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={doc?.document_name}
          ModalID={doc?.id}
          selectCompanyOrganisationID={selectCompanyOrganisationID}
          SelectCompanyOrganisationName={SelectCompanyOrganisationName}

        />
      );
    }
  };

  return (
    <div className="documentary-upload-docs-emp-container-contact-details">
      <Card className="shadow documentary-upload-docs-emp-card-contact-details">
        <div
          className="card-header text-center"
          style={{ fontSize: "20px", color: "#055837" }}
        >
          <b>Employment Documents</b>
        </div>
        <Form>
          <div
            className="row mb-3"
            style={{ margin: "5px", marginTop: "15px" }}
          >
            <div className="col-md-12 mb-3">
              <Form.Group controlId="selectCompanyOrganisation">
                <Form.Label htmlFor="selectEmploymentTypeSelect">
                  ▸ Select Company / Organisation{" "}
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <select
                  className="form-select custom-select"
                  id="selectCompanyOrganisation"
                  name="selectCompanyOrganisation"
                  style={{ height: "40px" }}
                  value={selectCompanyOrganisationID}
                  onChange={handleCompanyOrganisationChange}
                >
                  <option value="" disabled>
                    Select Company / Organisation
                  </option>
                  {companiesOrOrganisations.map((company) => (
                    <option key={company?.id} value={company?.id}>
                      {company?.company_organisation_name} (
                      {company?.employment_type})
                    </option>
                  ))}
                </select>
              </Form.Group>
            </div>

            {selectCompanyOrganisationID && (
              <>
                <div className="col-md-12">
                  <div
                    className="card-header text-center"
                    style={{
                      fontSize: "20px",
                      color: "#055837",
                      marginBottom: "15px",
                    }}
                  >
                    <b>
                      {" "}
                      ▸ Choose Documents for {
                        SelectCompanyOrganisationName
                      }{" "}
                    </b>
                  </div>

                  {loading ? (
                    <div className="d-flex justify-content-center mt-3">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    EmploymentDocsList.map((doc) =>
                      isDocUploaded(doc?.id) ? (
                        <div
                          key={doc?.id}
                          className="docUploaded"
                          style={{ marginBottom: "15px",border: "1px solid #055837" ,borderRadius:"5px",  }}
                        >
                          <div className="shadow p-4">
                            <div className="accordion-header-wrapper">
                              <button
                                className="accordion-button"
                                type="button"
                                onClick={() => handleOpenPopupModal(doc)}
                              >
                                <p className="accordion-header" id="headingOne">
                                  <div className="d-flex align-items-center">
                                    <span style={{ marginRight: "10px" }}>
                                      <CheckCircleFill />
                                    </span>
                                    <span>
                                      {doc.document_name}{" "}
                                      {doc.is_required === "1" && (
                                        <span style={{ color: "red" }}>*</span>
                                      )}{" "}
                                      - Uploaded Successfully!
                                    </span>
                                  </div>
                                </p>
                              </button>
                              <span style={{ marginRight: "20px" }}>✔️</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={doc?.id}
                          className="bg-white shadow p-4 docNotUploaded"
                          style={{ marginBottom: "15px" ,border: "1px solid #ffcc01",borderRadius:"5px", }}
                        >
                          <div className="accordion-header-wrapper">
                            <button
                              className="accordion-button"
                              type="button"
                              onClick={() => handleOpenPopupModal(doc)}
                            >
                              <p className="accordion-header" id="headingOne">
                                <div className="d-flex align-items-center">
                                  <span className="bullet-point"></span>
                                  <span>
                                    {doc?.document_name}{" "}
                                    {doc?.is_required === "1" && (
                                      <span style={{ color: "red" }}>*</span>
                                    )}
                                  </span>
                                </div>
                              </p>
                            </button>
                            <span style={{ marginRight: "20px" }}>⟫</span>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
              </>
            )}
          </div>
        </Form>
      </Card>
      {EmploymentDocsList.map((doc) => (
        <React.Fragment key={doc.id}>{renderUploadModal(doc)}</React.Fragment>
      ))}
    </div>
  );
};

export default EmploymentDocuments;
