import React, { useState, useEffect } from "react";
import "./Upload Documents.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import {
  get_Is_doc_uploadExtraDocumentsList_API,
  upload_get_extra_docs_names,
} from "../../../../../api";

import UploadModal from "./Upload Modal/Upload Modal";

const UploadDocuments = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [currentDoc, setCurrentDoc] = useState(null);
  const [ExtraDocsList, setExtraDocsList] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const fetchDocumentsListFunction = async () => {
    try {
      const response = await upload_get_extra_docs_names(
        pointerData?.pointer_id,
        "stage_3"
      );
      if (
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setExtraDocsList(response?.data?.response?.data);
      } else {
        console.error(
          "Error fetching documents:",
          response?.data?.response?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  useEffect(() => {
    if (pointerData?.pointer_id) {
      fetchDocumentsListFunction();
    }
  }, [pointerData?.pointer_id]);

  const getUploadedDocsList = async () => {
    try {
      setLoading(true);
      const response = await get_Is_doc_uploadExtraDocumentsList_API(
        pointerData?.pointer_id,
        "stage_3"
      );
      setLoading(false);

      if (response?.data?.response) {
        fetchDocumentsListFunction();
        const uploadedStatus = response.data.response?.data.map((doc) => ({
          ...doc,
          status: Number(doc?.status),
        }));
        setUploadedDocs(uploadedStatus);
      } else {
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (pointerData?.pointer_id) {
      getUploadedDocsList();
    }
  }, [pointerData?.pointer_id]);

  const handleOpenPopupModal = (doc) => {
    setCurrentDoc(doc);  // Set the document that the user clicked on
    setShowUploadModal(true);
  };

  const handleClosePopupModal = () => {
    setShowUploadModal(false);
    setCurrentDoc(null);
  };

  const isDocUploaded = (docId) => {
    docId = Number(docId);

    const doc = uploadedDocs.find((item) => {
      const itemId = Number(item.id);
      return itemId === docId;
    });

    return doc && Number(doc?.status) === 1;
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        ExtraDocsList.map((doc) =>
          isDocUploaded(doc?.id) ? (
            <div
              key={doc?.id}
              className="docUploaded"
              style={{
                marginBottom: "15px",
                border: "1px solid #055837",
                borderRadius: "5px",
              }}
            >
              <div
                className="shadow p-4"
                style={{
                  border: "1px solid #055837",
                  color: "#055837",
                  borderRadius: "5px",
                }}
              >
                <div className="accordion-header-wrapper">
                  <button
                    className="accordion-button"
                    type="button"
                    onClick={() => handleOpenPopupModal(doc)}
                  >
                    <p className="accordion-header" id="headingOne">
                      <div className="d-flex align-items-center">
                        <span style={{ marginRight: "10px" }}>
                          <CheckCircleFill />
                        </span>
                        <span>
                          {doc?.document_name}{" "}
                          {doc?.is_required === "1" && (
                            <span style={{ color: "red" }}>*</span>
                          )}{" "}
                          - Uploaded Successfully!
                        </span>
                      </div>
                    </p>
                  </button>
                  <span style={{ marginRight: "20px" }}>✔️</span>
                </div>
              </div>
            </div>
          ) : (
            <div
              key={doc?.id}
              className="bg-white shadow p-4 docNotUploaded"
              style={{
                marginBottom: "15px",
                border: "1px solid #ffcc01",
                borderRadius: "5px",
              }}
            >
              <div className="accordion-header-wrapper">
                <button
                  className="accordion-button"
                  type="button"
                  onClick={() => handleOpenPopupModal(doc)}
                >
                  <p className="accordion-header" id="headingOne">
                    <div className="d-flex align-items-center">
                      <span className="bullet-point"></span>
                      <span>
                        {doc?.document_name}{" "}
                        {doc?.is_required === "1" && (
                          <span style={{ color: "red" }}>*</span>
                        )}
                      </span>
                    </div>
                  </p>
                </button>
                <span style={{ marginRight: "20px" }}>⟫</span>
              </div>
            </div>
          )
        )
      )}

      {/* Render UploadModal for the currently selected document */}
      {showUploadModal && currentDoc && (
        <UploadModal
          show={showUploadModal}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={currentDoc?.document_name}
          ModalID={currentDoc?.id}
          allowedTypes={JSON.parse(currentDoc?.allowed_type || "[]")}
          isRequired={currentDoc?.is_required}
          ExtraComment_ID={currentDoc?.comment_id}
        />
      )}
    </>
  );
};

export default UploadDocuments;
