import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationButtons from "../Navigation Buttons/Navigation Buttons";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import { Col, Row, Button } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Create New Application.css"
import Heading from "../Heading/Heading";
import Stepper from "./stage_1/Stepper/Stepper";
import OccupationDetails from "./stage_1/Occupation Details/Occupation Details";
import PersonalDetails from "./stage_1/Personal Details/Personal Details";
import ContactDetails from "./stage_1/Contact Details/Contact Details";
import Identification from "./stage_1/Identification/Identification";
import USIAndAvetmiss from "./stage_1/USI & Avetmiss/USI & Avetmiss";
import EducationAndEmployment from "./stage_1/Education & Employment/Education & Employment";
import ReviewAndConfirm from "./stage_1/Review & Confirm/Review & Confirm";
import ApplicantDeclaration from "./stage_1/Applicant Declaration/Applicant Declaration";
import UploadDocuments from "./stage_1/Upload Documents/Upload Documents";


const CreateNewApplication = ({ onLogout }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const pointerData = location.state?.pointerData;




  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);
  const [showConfirmationModalForBack, setShowConfirmationModalForBack] =
    useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [logoutClicked, setLogoutClicked] = useState(false);
  const currentPath = location.pathname;

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  const handleBack = () => {
    setShowConfirmationModalForBack(true);
    setBackClicked(true);
  };

  const handleConfirmationForBackYes = () => {
    navigate("/user/dashboard");
  };

  const handleConfirmationForBackNo = () => {
    setShowConfirmationModalForBack(false);
    setBackClicked(false);
  };

  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    if (onLogout) {
      navigate("/");
    }
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  return (
    <>
      <Row className="mb-3">



      <Col>
  <Button
    className="back-button"
    variant=""
    onClick={handleBack}
    style={{
      // backgroundColor: "#f0f8ff",
      color: "#055837",
      marginLeft: "25px",
      outline: "none",
      boxShadow: "none",
    }}
  >
    <BsArrowLeft className="me-0" /> Back to Dashboard
  </Button>
</Col>




        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Stage 1 - Self Assessment" />
      </Row>
      

      <Stepper />
      

      {currentPath === "/user/create_new_application/stage_1/occupation_details" && <OccupationDetails pointerData={pointerData} />}

      {currentPath === "/user/create_new_application/stage_1/personal_details" && <PersonalDetails />}

      {currentPath === "/user/create_new_application/stage_1/contact_details" && <ContactDetails />}

      {currentPath === "/user/create_new_application/stage_1/identification" && <Identification />}

      {currentPath === "/user/create_new_application/stage_1/usi_and_avetmiss" && <USIAndAvetmiss />}

      {currentPath === "/user/create_new_application/stage_1/education_employment" && <EducationAndEmployment />}

      {currentPath === "/user/create_new_application/stage_1/review_and_confirm" && <ReviewAndConfirm />}

      {currentPath === "/user/create_new_application/stage_1/application_declaration" && <ApplicantDeclaration />}

      {currentPath === "/user/create_new_application/stage_1/upload_documents" && <UploadDocuments />}




      {logoutClicked && (
        <ConfirmationModal
          show={showConfirmationModalForLogout}
          onHide={handleConfirmationLogoutNo}
          onConfirm={handleConfirmationLogoutYes}
          title="Confirm Logout ?"
          message="Are you sure you want to log out?"
        />
      )}

      {backClicked && (
        <ConfirmationModal
          show={showConfirmationModalForBack}
          onHide={handleConfirmationForBackNo}
          onConfirm={handleConfirmationForBackYes}
          title="Confirm Back"
          message="Going back will navigate you to the dashboard. Are you sure you want to abort the create new application process right now? "

        />
      )}
    </>
  );
};

export default CreateNewApplication;
