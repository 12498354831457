import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import {
  submitLocationOfStage_3,
  fetchAddressDetailsByAPIByCityname,
} from "../../../../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LocationPopupModal = ({
  show,
  onHide,
  title,
  preferredLocation,
  preferredLocationID,
  pointerData,
  handleClearLocation,
getPreFilledDetailsFunction
}) => {
  const [loading, setLoading] = useState(false);

  const [termsChecked, setTermsChecked] = useState(false);

  const newLocationID = preferredLocation === "Online (Via Zoom)" ? "9" : preferredLocationID;


  const [fetchLocationsByAPI, setFetchLocationsByAPI] = useState([]);

  useEffect(() => {
    const getAddressDetails = async () => {
      const formData = new FormData();
      formData.append("city_name", preferredLocation);

      try {
      setLoading(true);

        const response = await fetchAddressDetailsByAPIByCityname(formData);
      setLoading(false);


        if (response.data?.response && response.data?.response?.data) {
          const location = response.data.response.data || {};
          setFetchLocationsByAPI(location);
        } else {
          console.error(
            "Error fetching address details:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
      setLoading(false);

        console.error("Error fetching address details:", error);
      }
    };

    if (pointerData?.pointer_id && preferredLocation) {
      getAddressDetails();
    }
  }, [pointerData?.pointer_id, preferredLocation]);


  
  const handleCancel = () => {
    onHide();
    handleClearLocation();
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!termsChecked) {
    //   toast.error("Agree to the terms.");
    //   document.getElementById("termsAndConditions").focus();
    //   return;
    // }

    const formData = new FormData();
    formData.append("preference_location", preferredLocation);
    formData.append("offline_location_id", newLocationID);


    try {
      setLoading(true);
      const response = await submitLocationOfStage_3(
        pointerData?.pointer_id,
        formData
      ); 
      setLoading(false);

      if (response?.data?.response?.response === true) {
        toast.success("Technical Interview Location Submitted Successfully.");
        getPreFilledDetailsFunction();

        onHide();
      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Technical Interview Location"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saving Technical Interview Location:", error);
      toast.error("Failed to save Technical Interview Location");
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title
            className="text-center"
            style={{
              fontSize: "18px",
              fontFamily: "Arial, sans-serif",
              color: "#055837",
            }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="text-center"
          style={{ fontFamily: "Arial, sans-serif" }}
        >
          {fetchLocationsByAPI && (
            <div>
              <div className="text-left d-flex">
                <strong style={{ marginRight: "10px", minWidth: "120px" }}>
                  City Name:
                </strong>
                <div>{fetchLocationsByAPI.city_name}</div>
              </div>
              <div className="text-left d-flex">
                <strong style={{ marginRight: "10px", minWidth: "120px" }}>
                  Country:
                </strong>
                <div>{fetchLocationsByAPI.country}</div>
              </div>
              <div className="text-left d-flex">
                <strong style={{ marginRight: "10px", minWidth: "120px" }}>
                  Venue:
                </strong>
                <div>{fetchLocationsByAPI.venue}</div>
              </div>
              <div className="text-left d-flex">
                <strong style={{ marginRight: "10px", minWidth: "120px" }}>
                  Office Address:
                </strong>
                <div>{fetchLocationsByAPI.office_address}</div>
              </div>
            </div>
          )}

          {/* <hr className="mb-2 mt-1 thick-hr" />

          <div
            className="form-group form-check mb-1"
            style={{
              marginTop: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              className="form-check-input"
              id="termsAndConditions"
              name="termsAndConditions"
              checked={termsChecked}
              onChange={() => setTermsChecked(!termsChecked)}
              style={{
                transform: "scale(1.3)",
                border: "1px solid #055837",
              }}
            />
            <label
              className="form-check-label"
              htmlFor="termsAndConditions"
              style={{
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Are you sure, you want to choose the above venuefor the Technical
              Interview.
            </label>
          </div> */}
        </Modal.Body>

        {loading && (
            <div className="d-flex justify-content-center mt-3">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}


        <Modal.Footer className="d-flex justify-content-center">
        
          <Button
            variant="light"
            onClick={handleCancel}
            className="w-50"  
            style={{
              maxWidth: "130px",
              backgroundColor: "#dc3545",
              color: "white",
              transition: "transform 0.2s",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Cancel
          </Button>
          <Button
            variant="light"
            onClick={handleSubmit}
            className="w-50"
            style={{
              maxWidth: "130px",
              backgroundColor: "#055837",
              color: "#ffcc01",
              transition: "transform 0.2s",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LocationPopupModal;
