import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { BiCheck } from "react-icons/bi";

const CaptchaComponent = ({ onCaptchaVerification }) => {
  const [captcha, setCaptcha] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [captchaError, setCaptchaError] = useState(false);
  const [crossedLines, setCrossedLines] = useState([]);
  const [dots, setDots] = useState([]);
  const [captchaHeight, setCaptchaHeight] = useState("45px");
  const [showCheckIcon, setShowCheckIcon] = useState(false);


  useEffect(() => {
    refreshCaptcha();
  }, []);

  useEffect(() => {
    updateCaptchaHeight();
  }, [captcha]);

  function updateCaptchaHeight() {
    const fontSize = 45;
    const lineHeight = 1.5;
    const height = fontSize * lineHeight + "px";
    setCaptchaHeight(height);
  }

  function generateCaptcha() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return captcha;
  }

  function refreshCaptcha() {
    const newCaptcha = generateCaptcha();
    setCaptcha(newCaptcha);
    setInputValue("");
    setCaptchaError(false);
    generateCrossedLines();
    generateDots();
    setShowCheckIcon(false);
    onCaptchaVerification(false);
  }

  function handleChangeCaptchaInput(event) {
    const value = event.target.value;
    setInputValue(value);

    if (value === captcha) {
      setCaptchaError(false);
      setShowCheckIcon(true);
      onCaptchaVerification(true); 
    } else {
      setCaptchaError(true);
      setShowCheckIcon(false);
      onCaptchaVerification(false);
    }
  }

  function generateCrossedLines() {
    const lines = [...Array(160)].map((_, i) => ({
      position: "absolute",
      top: `${getRandomPosition(90)}%`,
      left: `${getRandomPosition(70)}%`,
      right: `${getRandomPosition(5)}%`,
      width: "30%",
      height: "1px",
      background: "rgba(0, 0, 0, 0.3)",
      transform: `rotate(${getRandomAngle()}deg)`,
      zIndex: 0,
    }));
    setCrossedLines(lines);
  }

  function generateDots() {
    const dots = [...Array(400)].map((_, i) => ({
      position: "absolute",
      top: `${getRandomPosition(90)}%`,
      left: `${getRandomPosition(100)}%`,
      right: `${getRandomPosition(50)}%`,
      borderRadius: "50%",
      width: "2px",
      height: "2px",
      background: "rgba(0, 0, 0, 0.3)",
      zIndex: 0,
    }));
    setDots(dots);
  }

  function getRandomAngle() {
    return Math.random() * 360;
  }

  function getRandomPosition(max) {
    return Math.random() * max;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <h3
        style={{
          color: "black",
          fontSize: "12px",
          marginBottom: "5px",
          marginTop: "5px",
        }}
      >
        Captcha Code
      </h3>

      {/* Captcha display and refresh button */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* Captcha display */}
        <div
          style={{
            fontSize: "45px",
            letterSpacing: "10px",
            // marginBottom: "10px",
            color: "darkblue",
            position: "relative",
            overflow: "hidden",
            width: "260px",
            textAlign: "center",
            userSelect: "none",
            height: captchaHeight,

          }}
        >
          {captcha.split("").map((char, index) => (
            <span key={index}>{char}</span>
          ))}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            {crossedLines.map((line, index) => (
              <div key={index} style={line}></div>
            ))}
            {dots.map((dot, index) => (
              <div key={index} style={dot}></div>
            ))}
          </div>
        </div>

        {/* <button
          type="button"
          className="btn"
          title="Refresh Captcha"
          onClick={refreshCaptcha}
          style={{
            fontSize: "1.3rem",
            marginLeft: "8px",
            height: captchaHeight,
          }}
        >
          <i className="bi bi-arrow-clockwise"></i>
        </button> */}



        <button
  type="button"
  className="btn"
  title="Refresh Captcha"
  onClick={refreshCaptcha}
  style={{
    fontSize: "1.3rem",
    marginLeft: "8px",
    height: captchaHeight,
    outline: "none",
    boxShadow: "none",
  }}
>
  <i className="bi bi-arrow-clockwise"></i>
</button>





        
      </div>

      <Form
        style={{
          display: "inline-block",
          // marginTop: "5px",
          textAlign: "left",
          position: "relative", 
        }}
      >
        <Form.Group controlId="captchaInput">
          <Form.Label style={{ fontSize: "14px", display: "block" }}>
            Captcha Code <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <div style={{ position: "relative" }}>
            <Form.Control
              type="text"
              placeholder="Enter Captcha Code"
              value={inputValue}
              onChange={handleChangeCaptchaInput}
              isInvalid={captchaError}
              style={{
                width: "250px",
                height: "37px",
                // marginBottom: "8px",
                borderColor: captchaError ? "red" : "black",
              }}
            />

            {/* Conditional rendering of the check icon */}
            {showCheckIcon && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  color: "green",
                }}
              >
                <BiCheck size={20} />
              </div>
            )}
          </div>

          {captchaError && (
            <Form.Control.Feedback type="invalid">
              Verification code does not match. *
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Form>
    </div>
  );
};

export default CaptchaComponent;
